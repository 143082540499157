import React, {cloneElement} from 'react';
import {
    Show,
    ShowButton,
    SimpleShowLayout,
    DateField,
    List,
    Datagrid,
    TextField,
    TextInput,
    Filter,
    SelectInput,
    DateInput,
    downloadCSV, sanitizeListRestProps, ExportButton, TopToolbar, useListContext
} from 'react-admin';
import {unparse as convertToCSV} from 'papaparse/papaparse.min';
import AmegaEmployeeDelete from './amegaEmployeeDelete'
import AmegaEmployeeAdd from "./amegaEmployeeAdd";
import AmegaEmployeeUpdate from "./amegaEmployeeUpdate";

const exporter = amegaEmployees => {
    const employeesForExport = amegaEmployees.map(employee => {
        if (employee.createdAt != null) {
            let createdAt = employee.createdAt.split('T');
            employee.createdAt = createdAt[0];
        }
        if (employee.updatedAt != null) {
            let updatedAt = employee.updatedAt.split('T');
            employee.updatedAt = updatedAt[0];
        }

        return employee;
    });
    const csv = convertToCSV({data: employeesForExport});
    downloadCSV(csv, 'amegaEmployees');
}

const AmegaEmployeeFilter = props => (
    <Filter {...props}>
        <TextInput label="Поиск" source="q" alwaysOn/>
        <SelectInput label="Удален" source="notDeleted" emptyText="Все" alwaysOn choices={[
            {id: true, name: 'Нет'},
        ]}/>
        <DateInput label="Дата создания от" source="dateFrom" alwaysOn/>
        <DateInput label="Дата создания до" source="dateTo" alwaysOn/>
    </Filter>
)

const AmegaEmployeeActions = props => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        showAddButton,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        showFilter,
        total,
    } = useListContext();

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            {
                exporter &&
                <ExportButton
                    disabled={total === 0}
                    resource={resource}
                    sort={currentSort}
                    filterValues={filterValues}
                    maxResults={maxResults}
                />
            }
            {
                showAddButton &&
                <AmegaEmployeeAdd/>
            }
        </TopToolbar>
    )
}

export const AmegaEmployeeList = ({permissions, ...props}) => {
    const isChiefAdmin = permissions?.includes('super_admin') || permissions?.includes('chief_admin')
    return (
        <List filters={<AmegaEmployeeFilter/>}
              actions={<AmegaEmployeeActions showAddButton={isChiefAdmin}/>}
              exporter={isChiefAdmin ? exporter : false}
              title="Сотрудники Амега" {...props}>
            <Datagrid>
                <TextField source="id" label="Ид"/>
                <DateField source="createdAt" showTime label="Дата создания"/>
                <DateField source="deletedAt" showTime label="Дата удаления"/>
                <TextField source="name" label="Имя"/>
                <TextField source="catalog1C" label="Каталог 1С"/>
                <TextField source="referralCode" label="Реферальный код"/>
                <TextField source="city" label="Город"/>
                <ShowButton/>
            </Datagrid>
        </List>
    );
};

export const AmegaEmployeeShow = ({permissions, ...props}) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" label="Ид"/>
            <DateField source="createdAt" showTime label="Дата создания"/>
            <DateField source="updatedAt" showTime label="Дата последнего обновления"/>
            <DateField source="deletedAt" showTime label="Дата удаления"/>
            {
                (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                <AmegaEmployeeDelete label="Удалить"/>
            }
            <TextField source="name" label="Имя"/>
            <TextField source="catalog1C" label="Каталог 1С"/>
            <TextField source="referralCode" label="Реферальный код"/>
            <TextField source="city" label="Город"/>
            {
                (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                <AmegaEmployeeUpdate isAdmin={true} label="Изменить"/>
            }
        </SimpleShowLayout>
    </Show>
);

