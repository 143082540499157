import React, {useRef, useState} from "react";
import Button from "@material-ui/core/Button";
import {API_ADMIN} from "../const";
import FetchRequest from "../fetchRequest";
import {useNotify} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function GoodsFileUpload(props) {
    const {label} = props;
    const notify = useNotify();
    const inputFile = useRef();
    const [file, setFile] = useState(null)
    let [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };

    const clearSelectedFile = () => {
        setFile(null)
        inputFile.current.value = null
    }

    const uploadFile = () => {
        let reader = new FileReader();
        reader.onload = () => {
            const access_token = localStorage.getItem('access_token');
            const request = new Request(API_ADMIN + '/goodsupdates/upload',
                {
                    method: 'POST',
                    headers: new Headers({
                        'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        'Authorization': `Bearer ${access_token}`
                    }),
                    body: reader.result,
                });
            FetchRequest('Ошибка загрузки нового списка допов', request, notify, () => {
                window.location.reload();
            });
        }

        reader.readAsArrayBuffer(file)
    };

    const handleClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        const files = event.target.files
        if (files.length === 1) {
            setFile(files[0])
        } else {
            setFile(null)
        }
    };

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Загрузка списка доп материалов</DialogTitle>
                <input type='file' id='file'
                       accept='application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                       ref={inputFile} onChange={handleClick}/>
                <Button onClick={clearSelectedFile} disabled={file === null}>Очистить</Button>
                <Button onClick={uploadFile} disabled={file === null}>Сохранить</Button>
                <Button onClick={handleClose}>Отмена</Button>
            </Dialog>
        </React.Fragment>
    )
}
