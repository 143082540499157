import React from 'react'
import Button from '@material-ui/core/Button'
import { postJson } from '../api/postJson'
import { useNotify } from 'react-admin'
import { API_BACKEND } from '../const'

class RecalcOrderRequest {
    constructor(order) {
        this.orderInfo = {
            orderId: order.id
        }

        this.constructions = order.constructions ?? []
        this.goods = order.goods ?? []
        this.services = order.services ?? []
        this.userActions = order.userActions ?? []

        // Use fields from order to override partner/filial/dealer.
        this.partnerId = order.partnerId
        this.filialId = order.filialId
        this.dealerId = order.dealerId
    }
}

export const RecalcOrder = ({ record }) => {
    const notify = useNotify()

    const handleClick = () => {
        const req = new RecalcOrderRequest(record)

        notify('Запрос на пересчет отправлен')
        postJson(`/v1/orders/${record.id}`, req, API_BACKEND)
            .then(() => {
                notify('Заказ был пересчитан успешно')
                setTimeout(() => document.location.reload(), 1000)
            })
            .catch(err => notify(`Ошибка пересчета заказа: ${err}`, 'error'))
    }

    return (
        <Button onClick={handleClick} variant="outlined" color="primary" style={{ margin: '1em 0' }}>
            Пересчитать заказ
        </Button>
    )
}
