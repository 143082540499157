import {
    Datagrid,
    DateField, DateInput, ExportButton, Filter,
    List,
    ReferenceField, ReferenceInput,
    sanitizeListRestProps,
    TextField,
    TopToolbar
} from "react-admin";
import React from "react";
import {RecordAutocomplete} from "../components/recordAutocomplete";
import GoodsFileUpload from "./goodsFileUpload";
import GoodsFileDownload from "./goodsFileDownload";

const DealerFilter = props => (
    <Filter {...props}>
        <ReferenceInput label="Автор" source="authorId" reference="users" alwaysOn perPage={10}>
            {RecordAutocomplete()}
        </ReferenceInput>
        <DateInput label="Дата обновления от" source="dateFrom" alwaysOn/>
        <DateInput label="Дата обновления до" source="dateTo" alwaysOn/>
    </Filter>
)

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        setFilters,
        ...rest
    } = props;
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <GoodsFileUpload label="Загрузить новый список"/>
            <ExportButton/>
        </TopToolbar>
    );
};

export const GoodsUpdatesList = ({permissions, ...props}) => (
    <List empty={false} title="Обновления допов"
          exporter={permissions?.includes('super_admin') || permissions?.includes('chief_admin') ? props.exporter : false}
          filters={<DealerFilter/>} {...props} actions={<ListActions/>}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="Ид"/>
            <DateField source="updatedAt" showTime label="Дата загрузки"/>
            <ReferenceField source="authorId" reference="users" label="Автор">
                <TextField source="username"/>
            </ReferenceField>
            <GoodsFileDownload label="Скачать файл"/>
        </Datagrid>
    </List>
);