import React, {useEffect, useState} from 'react'
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    DateTimeInput,
    Filter,
    FunctionField,
    List,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
    useNotify,
    TopToolbar,
    ExportButton,
    Button,
    sanitizeListRestProps
} from 'react-admin'
import { FilterList } from "@material-ui/icons"
import {get} from '../api/get'
import {RecordAutocomplete} from '../components/recordAutocomplete'

const useEventTypes = () => {
    const notify = useNotify()

    const [eventTypes, setEventTypes] = useState([])
    useEffect(() => {
        get('/admin/adminauditevents/types')
            .then(response => {
                setEventTypes(JSON.parse(response))
            })
            .catch(e => {
                console.error(e)
                notify('Не удалось получить данные')
            })
    }, [notify])

    return eventTypes
}

const AdminAuditEventsFilter = (props) => {
    const eventTypes = useEventTypes()
    return (
        <Filter {...props}>
            <TextInput label="Поиск" source="q" alwaysOn/>

            <SelectInput label="Тип" source="eventType" emptyText="Все" alwaysOn choices={eventTypes.map(
                ({id, label}) => ({id, name: label}))}/>

            <ReferenceInput label="Автор" source="authorId" reference="users" alwaysOn perPage={10}
                            filter={{userRoles: ['CommonAdmin', 'ChiefAdmin', 'SuperAdmin', 
                            'RMAdmin', 'NonqualifiedBossManager', 'BossManager', 'ChiefManager', 'CommonManager']}}>
                <AutocompleteInput optionText="username" emptyText="Все"/>
            </ReferenceInput>

            <ReferenceInput label="Заказ" source="orderId" reference="orders" alwaysOn perPage={10}>
                <AutocompleteInput optionText="orderNumber" emptyText="Все"/>
            </ReferenceInput>

            <ReferenceInput label="Пользователь" source="userId" reference="users" alwaysOn perPage={10}>
                <AutocompleteInput optionText="username" emptyText="Все"/>
            </ReferenceInput>

            <ReferenceInput label="Контрагент" source="dealerId" reference="dealers" alwaysOn perPage={10}>
                {RecordAutocomplete()}
            </ReferenceInput>

            <ReferenceInput label="Филиал" source="filialId" reference="filials" alwaysOn perPage={10}>
                <AutocompleteInput optionText="name" emptyText="Все"/>
            </ReferenceInput>

            <ReferenceInput label="Партнер" source="partnerId" reference="partners" alwaysOn perPage={10}>
                {RecordAutocomplete()}
            </ReferenceInput>

            <NumberInput label="Ид оповещения" source="alertId" alwaysOn/>
            <DateTimeInput label="Дата создания от" source="dateFrom" alwaysOn/>
            <DateTimeInput label="Дата создания до" source="dateTo" alwaysOn/>
        </Filter>
    )
}

const ListActions = (props) => {
    const {
      className,
      exporter,
      filters,
      maxResults,
      setFilters,
      ...rest
    } = props;
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <Button onClick={() => setFilters({})} label="Сбросить фильтры">
          <FilterList />
        </Button>
        <ExportButton/>
      </TopToolbar>
    );
  };

export const AdminAuditEventsList = ({permissions, ...props}) => {
    const eventTypes = useEventTypes()
    return (
        <List actions={<ListActions/>} filters={<AdminAuditEventsFilter/>}
              exporter={
                  permissions?.includes('super_admin')
                  || permissions?.includes('chief_admin')
                      ? props.exporter
                      : false}
              title="Аудит действий" {...props}
              sort={{field: 'createdAt', order: 'DESC'}}
        >
            <Datagrid>
                <TextField source="id" label="Ид"/>
                <DateField source="createdAt" showTime label="Дата создания"/>

                <FunctionField label="Тип" render={record => {
                    const item = eventTypes.find(_ => _.id === record.eventType)
                    return item ? item.label : record.eventType
                }}/>

                <ReferenceField source="authorId" label="Автор" reference="users" link="show">
                    <TextField source="username"/>
                </ReferenceField>

                <ReferenceField source="orderId" label="Заказ" reference="orders" link="show">
                    <TextField source="orderNumber"/>
                </ReferenceField>

                <ReferenceField source="userId" label="Пользователь" reference="users" link="show">
                    <TextField source="username"/>
                </ReferenceField>

                <ReferenceField source="dealerId" label="Контрагент" reference="dealers" link="show">
                    <TextField source="name"/>
                </ReferenceField>

                <ReferenceField source="filialId" label="Филиал" reference="filials" link="show">
                    <TextField source="name"/>
                </ReferenceField>

            <ReferenceField source="partnerId" label="Партнер" reference="partners" link="show">
                    <TextField source="name"/>
                </ReferenceField>

                <ReferenceField source="alertId" label="Оповещение" reference="alerts" link="show">
                    <TextField source="description"/>
                </ReferenceField>

                <FunctionField label={'Изменение'} render={record => {
                    if ('amount' in record) {
                        return JSON.stringify(record.amount)
                    }

                    const hasOld = record.old !== undefined && record.old !== null
                    const hasNew = record.new !== undefined && record.new !== null

                    if (hasOld || hasNew) {
                        return `${hasOld ? JSON.stringify(record.old) : ''} -> ${hasNew ? JSON.stringify(record.new) : ''}`
                    }

                    return ''
                }}/>

                <TextField source="comment" label="Комментарий"/>
            </Datagrid>
        </List>
    )
}
