import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import React from 'react'
import { useNotify } from 'react-admin'
import { API_ADMIN } from '../const'

export default function PartnerDeleteClient(props) {
    const { record, label } = props

    const [open, setOpen] = React.useState(false)
    const [comment, setComment] = React.useState('')

    const notify = useNotify()
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleClick = () => {
        const access_token = localStorage.getItem('access_token')
        const request = new Request(API_ADMIN + '/partners/' + record.id + '/deleteclient',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }),
                body: JSON.stringify({ comment })
            })
        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    response.json().then(json => {
                        notify(`Ошибка удаления клиента: ${json}`)
                    })
                } else {
                    response.text().then(json => {
                        notify(`${json}`)
                    })
                }
            })
            .catch((e) => {
                notify(`Ошибка выполнения запроса`, 'error')
            })
            .finally(() => {
                window.history.back()
                setOpen(false)
            })
    }

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Действительно удалить клиента?</DialogTitle>
                <TextField value={comment} placeholder="Причина удаления" style={{ padding: '1em' }}
                           onChange={e => setComment(e.target.value)}/>
                <List>
                    <ListItem button onClick={handleClick} disabled={comment === ''}>
                        <ListItemText primary="Да"/>
                    </ListItem>
                    <ListItem button onClick={handleClose}>
                        <ListItemText primary="Нет"/>
                    </ListItem>
                </List>

            </Dialog>
        </React.Fragment>
    )
}

PartnerDeleteClient.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object
}
