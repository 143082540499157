import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {useNotify} from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {API_ADMIN} from '../const'
import {Tooltip} from "@material-ui/core";

export default function OrderFullRecalc(props) {
    const {record, label} = props;
    let [open, setOpen] = React.useState(false);
    const notify = useNotify();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };

    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_ADMIN + '/orders/'+record.id+'/fullrecalc',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}` }),
            });
        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    response.json().then(json => {
                        notify(`Ошибка обновления заказа: ${json}`);
                    })
                } else {
                    response.text().then(json => {
                        notify(`${json}`);
                    })
                }
            })
            .catch((e) => {
                notify(`Ошибка выполнения запроса`, 'error')
            })
            .finally(() => {
                setOpen(false)
            });
    };

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Действительно поставить признак принудительного пересчета для заказа?</DialogTitle>
                <List>
                    <Tooltip title="Принудительный пересчет - пересчет всего заказа, без проверки на наличие изменений" arrow>
                        <div>
                            <ListItem button onClick={handleClick}>
                                <ListItemText primary="Да"/>
                            </ListItem>
                        </div>
                    </Tooltip>
                    <ListItem button onClick={handleClose}>
                        <ListItemText primary="Нет"/>
                    </ListItem>
                </List>

            </Dialog>
        </React.Fragment>
    )
}

OrderFullRecalc.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};
