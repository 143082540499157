import React from "react";
import {Button, useNotify} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import {API_ADMIN} from "../const";

export default function WindrawServicesStartCreateService(props) {
    let [open, setOpen] = React.useState(false);
    const notify = useNotify();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };

    let factoryId = 3;

    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_ADMIN + '/windrawservices/startcreate/' + factoryId,
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}` }),
            });
        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    response.text().then(json => {
                        notify(`${json}`);
                    })
                } else {
                    response.text().then(json => {
                        notify(`${json}`);
                    })
                }
            })
            .catch((e) => {
                notify(`Ошибка выполнения запроса`, 'error')
            })
            .finally(() => {
                setOpen(false)
            });
    };

    const handlePerm = () => {
        factoryId = 3;
        handleClick();
    };

    const handleMsk = () => {
        factoryId = 4;
        handleClick();
    };

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    onClick={handleClickOpen} label="Запустить сервис создания заказов"/>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Выберите завод для запуска сервиса создания заказов:</DialogTitle>
                <Button onClick={handlePerm} label="Пермь"/>
                <Button onClick={handleMsk} label="МСК"/>
                <Button onClick={handleClose} label="Нет"/>
            </Dialog>
        </React.Fragment>
    )
}

WindrawServicesStartCreateService.propTypes = {
    label: PropTypes.string
};