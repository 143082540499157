import { API_ADMIN } from '../const'

export const postJson = async (path, body, api = API_ADMIN) => {
    const response = await fetch(new URL(path, api).href, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage['access_token']}`
        },
        body: body ? JSON.stringify(body) : undefined
    })

    if (!response.ok) {
        throw Error(await response.text())
    }

    return response.text()
}
