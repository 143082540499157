import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {useNotify} from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {API_BACKEND} from '../const'

export default function OrderCreateAt1C(props) {
    const {record, label} = props;
    let [open, setOpen] = React.useState(false);
    const notify = useNotify();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };
    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_BACKEND + '/adminorders/'+record.id+'/createat1c',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}` }),
            });
        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    response.text().then(json => {
                        notify(`${json}`);
                    })
                } else {
                    response.text().then(json => {
                        notify(`${json}`);
                    })
                }
            })
            .catch((e) => {
                notify(`Ошибка выполнения запроса`, 'error')
            })
            .finally(() => {
                window.history.back();
                setOpen(false)
            });
    };
    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    disabled={record.state !== 'booking' && record.state !== 'paid'}
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Создать заказ в 1С?</DialogTitle>
                <List>
                    <ListItem button onClick={handleClick}>
                        <ListItemText primary="Да"/>
                    </ListItem>
                    <ListItem button onClick={handleClose}>
                        <ListItemText primary="Нет"/>
                    </ListItem>
                </List>

            </Dialog>
        </React.Fragment>
    )
}

OrderCreateAt1C.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};
