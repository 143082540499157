import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {useNotify} from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {API_ADMIN} from '../const'

export default function PartnerDiscountVisibility(props) {
    const {record, label, isAdmin} = props;
    let [open, setOpen] = React.useState(false);
    const notify = useNotify();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };
    const changeDiscountVisibility = (value) => {
        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_ADMIN + '/partners/' + record.id + '/discountvisibility',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }),
                body: JSON.stringify(record, ['showDiscountType']),
            });
        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    response.json().then(json => {
                        notify(`Ошибка смены видимости скидки: ${json}`, 'error');
                    })
                } else {
                    response.text().then(json => {
                        notify(`${json}`);
                    })
                }
            })
            .catch((e) => {
                notify(`Ошибка выполнения запроса`, 'error')
            })
            .finally(() => {
                window.history.back();
                setOpen(false)
            });
    };
    const turnOn = () => {
        record.showDiscountType = 'Show'
        changeDiscountVisibility(record)
    };
    const turnOff = () => {
        record.showDiscountType = 'DoNotShow'
        changeDiscountVisibility(record)
    }
    const turnBan = () => {
        record.showDiscountType = 'CanNotShow'
        changeDiscountVisibility(record)
    }
    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Видимость скидки для {record.name}:</DialogTitle>
                <List>
                    <ListItem button onClick={turnOn} disabled={record.showDiscountType === 'Show' || (!isAdmin && record.showDiscountType === 'CanNotShow')}>
                        <ListItemText primary="Включить видимость"/>
                    </ListItem>
                    <ListItem button onClick={turnOff} disabled={record.showDiscountType === 'DoNotShow' || (!isAdmin && record.showDiscountType === 'CanNotShow')}>
                        <ListItemText primary="Отключить видимость"/>
                    </ListItem>
                    {
                        isAdmin &&
                        <ListItem button onClick={turnBan} disabled={record.showDiscountType === 'CanNotShow'}>
                            <ListItemText primary="Запретить изменение видимости"/>
                        </ListItem>
                    }
                    <ListItem button onClick={handleClose}>
                        <ListItemText primary="Отмена"/>
                    </ListItem>
                </List>

            </Dialog>
        </React.Fragment>
    )
}

PartnerDiscountVisibility.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};