import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {useNotify} from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {API_ADMIN} from '../../const'
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import {cities} from "../../cities";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {makeStyles} from "@material-ui/core/styles";

export default function AmegaEmployeeUpdate(props) {
    const {record, label} = props;

    let [open, setOpen] = React.useState(false);
    const notify = useNotify();
    const useStyles = makeStyles({
        option: {
            fontSize: 15,
            '& > span': {
                marginRight: 10,
                fontSize: 18,
            },
        },
    });
    const classes = useStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false)
    };

    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_ADMIN + '/amegaemployees/' + record.id,
            {
                method: 'PUT',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }),
                body: JSON.stringify(record, ['name', 'catalog1C', 'referralCode', 'cityId']),
            });

        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    response.json().then(json => {
                        notify(`Ошибка обновления сотрудника: ${json}`);
                    })
                } else {
                    notify('Данные о сотруднике обновлены');
                }
            })
            .catch(() => {
                notify(`Ошибка выполнения запроса`, 'error')
            })
            .finally(() => {
                window.history.back();
                setOpen(false)
            });
    };

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Обновить партнера</DialogTitle>
                <DialogContent>
                    <TextField
                        defaultValue={record.name}
                        autoFocus
                        margin="dense"
                        label="Имя"
                        type="text"
                        onChange={(event)=>{record.name = event.target.value}}
                    />
                    <TextField
                        defaultValue={record.catalog1C}
                        autoFocus
                        margin="dense"
                        label="Каталог 1С"
                        type="text"
                        onChange={(event)=>{record.catalog1C = event.target.value}}
                    />
                    <TextField
                        defaultValue={record.referralCode}
                        autoFocus
                        margin="dense"
                        label="Реферальный код"
                        type="text"
                        onChange={(event)=>{record.referralCode = event.target.value}}
                    />
                    <Autocomplete
                        id="country-select-demo"
                        style={{ width: 300 }}
                        options={cities}
                        classes={{
                            option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.name}
                            </React.Fragment>
                        )}
                        onChange={(event, newValue) => {
                            record.cityId = newValue.id
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Выберите город"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleClick} color="primary">
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

AmegaEmployeeUpdate.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};
