import React, {useMemo} from 'react'
import Button from '@material-ui/core/Button'
import { postJson } from '../api/postJson'
import { useNotify } from 'react-admin'

export const CompleteOrder = ({ record }) => {
    const notify = useNotify()
    const isHide = useMemo(() => record.state === 'needs_recalc' || record.state === 'draft', [record])
    const handleClick = () => {
        notify('Запрос на заверешение заказа отправлен')
        postJson(`/admin/orders/${record.id}/forcecomplete`, {})
            .then(() => {
                notify('Заказ был завершен успешно')
                setTimeout(() => document.location.reload(), 1000)
            })
            .catch(err => notify(`Ошибка завершения заказа: ${err}`, 'error'))
    }

    return (
        <Button disabled={isHide} onClick={handleClick} variant="outlined" color="primary" style={{ margin: '1em 0' }}>
            Завершить заказ
        </Button>
    )
}
