import React, {ChangeEvent, ReactNode, useCallback} from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'

export interface OrderDiscounts {
    st: number,
    gls: number,
    gds: number,
    met: number,
    nst: number,
    lam: number,
    wAl: number,
    cAl: number,
    ttl: number,
}

export interface PartnerDiscounts {
    st: number,
    nst: number,
    lam: number,
    wAl: number,
    cAl: number,
}

export type Discounts = OrderDiscounts & PartnerDiscounts
export type Notification  = {notification: boolean, }

const uiNames = (discount: keyof Discounts): string | null => {
    switch (discount) {
        case 'st':
            return 'Конструкции/Стандарт'
        case 'gls':
            return 'Стеклопакеты'
        case 'gds':
            return 'Допы'
        case 'met':
            return 'Металл'
        case 'nst':
            return 'Нестандарт'
        case 'lam':
            return 'Ламинация'
        case 'wAl':
            return 'Теплый алюминий'
        case 'cAl':
            return 'Холодный алюминий'
        case 'ttl':
            return null // Hide property field.
    }
}

interface Props {
    label: string
    discounts: Discounts,
    notification: Notification,
    onChange: (value: Discounts) => void
    onSubmit?: () => void
    children?: ReactNode
}

export const discountEditorFieldStyle = {margin: '0.5em'}

export const DiscountsEditor = ({label, discounts, onChange, onSubmit, children}: Props) => {
    const setDiscount = useCallback((type: keyof Discounts) =>
        (event: ChangeEvent<HTMLInputElement>) => {
            onChange({
                ...discounts,
                [type]: parseFloat(event.target.value), // Update one prop in the object by its name.
            })
        }, [discounts, onChange])

    const [open, setOpen] = React.useState(false)

    const handleOpen = useCallback(() => setOpen(true), [setOpen])
    const handleClose = useCallback(() => setOpen(false), [setOpen])
    const handleSubmit = useCallback(() => {
        setOpen(false)
        onSubmit?.call(undefined)
    }, [onSubmit])
    const handleSubmitAndNotify = useCallback(() => {
        setOpen(false)
        onSubmit?.call(undefined)
    }, [onSubmit])

    return (
        <>
            <Button variant="outlined" color="primary" onClick={handleOpen}>{label}</Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">{label}</DialogTitle>
                <DialogContent>
                    {(Object
                        .keys(discounts) as (keyof Discounts)[])
                        .filter(key => uiNames(key) !== null)
                        .map((type) => (
                            <TextField
                                key={type}
                                style={discountEditorFieldStyle}
                                value={discounts[type]}
                                autoFocus
                                margin="dense"
                                label={uiNames(type)}
                                type="number"
                                onChange={setDiscount(type)}
                            />
                        ))}

                    {children}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Сохранить
                    </Button>
                    <Button onClick={handleSubmitAndNotify} color="primary">
                        Сохранить и уведомить о скидке
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
