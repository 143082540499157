import { API_LOGIN } from './const'
import decodeJwt from 'jwt-decode';

export default {
    // called when the user attempts to log in
    login: ({ username, password }) =>  {
        const request = new Request(API_LOGIN, {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ access_token }) => {
                const decodedToken = decodeJwt(access_token);
                localStorage.setItem('access_token', access_token);
                let roles = [];
                if (decodedToken.per.SuperAdmin !== undefined) {
                    roles.push('super_admin');
                }
                if (decodedToken.per.ChiefAdmin !== undefined) {
                    roles.push('chief_admin');
                }
                if (decodedToken.per.CommonAdmin !== undefined) {
                    roles.push('common_admin');
                }
                if (decodedToken.per.RMAdmin !== undefined) {
                    roles.push('rm_admin');
                }
                if (decodedToken.sub === '3744' || decodedToken.sub === '891') {
                    roles.push('elohina_admin')
                }
                console.log(decodedToken)
                localStorage.setItem('permissions', roles);
            });
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('permissions');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            localStorage.removeItem('access_token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        return localStorage.getItem('access_token')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        const roles = localStorage.getItem('permissions');
        console.log(roles)
        return roles ? Promise.resolve(roles) : Promise.reject();
    },
};
