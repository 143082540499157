import React, {Fragment} from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    DateInput,
    Filter,
    FunctionField,
    List,
    NumberField,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    SelectArrayInput,
    SelectInput,
    Show,
    ShowButton,
    SimpleShowLayout,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    BooleanInput,
    TopToolbar,
    ExportButton,
    Button,
    sanitizeListRestProps
} from 'react-admin';
import { FilterList } from "@material-ui/icons";
import OrderUnbook from "./orderUnbook";
import OrderAutoUnbook from "./orderAutoUnbook";
import OrderToDev from "./orderToDev";
import OrderSetDiscounts from "./discounts/orderSetDiscounts";
import OrderCreateAtWindrawAnd1C from "./orderCreateAtWindraw";
import OrderCreateAt1C from "./orderCreateAt1C";
import OrderBook from "./orderBook";
import OrderRestore from "./orderRestore";
import OrderGetOffer from "./orderGetOffer";
import OrderSetGOSTParam from "./ordersSetGOSTParam";
import OrderSetHandleParams from "./orderSetHandleParams";
import OrderToProd from "./orderToProd";
import {HumanReadableCancellationReason} from "./cancellationReasons";
import OrderUnbookAtWindraw from "./orderUnbookAtWindraw";
import OrderBatchRecalc from "./orderBatchRecalc";
import {ForceDirectShipping} from './forcedValues/forceDirectShipping'
import {ForceShippingCost} from './forcedValues/forceShippingCost'
import {ForceWeekdays, numbersToWeekdaysMap} from './forcedValues/forceWeekdays'
import {ForceWarehouse} from './forcedValues/forceWarehouse'
import {ForceShippingAddress} from './forcedValues/forceShippingAddress'
import {Address} from '../maps/models/address'
import {ForceShippingType} from './forcedValues/forceShippingType'
import {RecalcOrder} from './recalcOrder'
import {HumanReadablePaymentState} from "./paymentStates";
import {CreatePreorder} from './createPreorder'
import OrderProductsSeparationStep from "./orderSetProductsSeparationStep";
import OrderRestoreUnbookAtWindraw from "./orderRestoreUnbookAtWindraw";
import OrderFullRecalc from "./orderSetFullRecalc";
import OrderSetShippingDate from "./orderSetShippingDate";
import {ConstructionsTab} from './tabs/constructions'
import {GoodsTab} from "./tabs/goods";
import {RecordAutocomplete} from '../components/recordAutocomplete'
import { CompleteOrder } from './orderComplete';
import OrderSetPPGOSTParam from "./ordersSetPPGOSTParam";

function FramerPointsSymbol() {
    return (
        <svg width="10" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.617 0h2.18c1.251 0 2.165.276 2.74.829.584.542.877 1.304.877 2.285 0 .962-.297 1.724-.89 2.286-.585.552-1.494.829-2.728.829h-.37v.957h2.87v1.057h-2.87V10H3.988V8.243H1.296V7.186h2.692v-.957h-.37c-1.234 0-2.143-.277-2.728-.829C.297 4.838 0 4.076 0 3.114 0 2.134.292 1.371.877.83 1.452.276 2.365 0 3.617 0Zm.37 5.029v-3.8h-.37c-.816 0-1.396.161-1.739.485-.352.315-.528.786-.528 1.415 0 .638.171 1.114.514 1.428.344.314.928.472 1.753.472h.37Zm1.439 0v-3.8h.37c.816 0 1.396.161 1.74.485.352.315.528.786.528 1.415 0 .638-.172 1.114-.515 1.428-.343.314-.927.472-1.753.472h-.37Z" fill="#303030"></path>
        </svg>
    )
}

function FormattedOrderCost({ order, cost }) {
    return order.calculatedWithFramerPoints || order.isPaidByFramerPoints
        // Order was paid by framer points or wasn't paid yet, but was calculated with framer points (and likely will be paid by framer points).
        ? <span>{cost}&nbsp;<FramerPointsSymbol/></span>
        // Order was paid by rubles.
        : <span>{cost}&nbsp;₽</span>
}

function Filters(permissions, ...props) {
    if (permissions?.includes('rm_admin')) {
        return (
            <Filter {...props}>
                <TextInput label="Поиск" source="q" alwaysOn/>
                <BooleanInput label="Строгий поиск" source="strictSearch" alwaysOn/>
                <ReferenceInput label="Партнер" source="partnerId" reference="partners" alwaysOn perPage={10}>
                    {RecordAutocomplete()}
                </ReferenceInput>
                <SelectArrayInput label="Статус" source="state" alwaysOn choices={[
                    {id: "draft", name: 'Расчет (draft)'},
                    {id: "calc_errors", name: 'Ошибки построения (calc_errors)'},
                    {id: "needs_recalc", name: 'Необходим пересчет (needs_recalc)'},
                    {id: "awaiting_payment", name: 'Ожидает оплаты (awaiting_payment)'},
                    {id: "paid", name: 'Оплачен (paid)'},
                    {id: "booking", name: 'Передаем в производство (booking)'},
                    {id: "booked", name: 'Забронирован (booked)'},
                    {id: "cancel_requested", name: 'Запрос отмены (cancel_requested)'},
                    {id: "cancelled", name: 'Отменен (cancelled)'},
                    {id: "producing", name: 'В производстве (producing)'},
                    {id: "production_error", name: 'Ошибка производства (production_error)'},
                    {id: "ready_for_pickup", name: 'Готов к отгрузке (ready_for_pickup)'},
                    {id: "produced__shipping_to_client", name: 'Готов, ожидается доставка (produced_shipping_to_client)'},
                    {
                        id: "produced__shipping_to_warehouse",
                        name: 'Готов, ожидается доставка на склад (produced_shipping_to_warehouse)'
                    },
                    {id: "shipping_to_client_address", name: 'Доставка по адресу (shipping_to_client_address)'},
                    {id: "shipping_to_warehouse", name: 'Доставка на склад (shipping_to_warehouse)'},
                    {
                        id: "at_warehouse_awaiting_shipping",
                        name: 'На складе, ожидается доставка (at_warehouse_awaiting_shipping)'
                    },
                    {id: "at_warehouse_awaiting_pickup", name: 'На складе, ожидается самовывоз (at_warehouse_awaiting_pickup)'},
                    {id: "completed", name: 'Завершен (completed)'},
                ]}/>
                <DateInput label="Дата создания от" source="dateFrom" alwaysOn/>
                <DateInput label="Дата создания до" source="dateTo" alwaysOn/>
                <NumberInput label="Стоимость конструкций без наценки от, ₽" source="minConstrAmegaCost" alwaysOn/>
                <NumberInput label="Стоимость конструкций без наценки до, ₽" source="maxConstrAmegaCost" alwaysOn/>
            </Filter>
        )
    } else {
        return (
            <Filter {...props}>
                <TextInput label="Поиск" source="q" alwaysOn/>
                <BooleanInput label="Строгий поиск" source="strictSearch" alwaysOn/>
                <ReferenceInput label="Партнер" source="partnerId" reference="partners" alwaysOn perPage={10}>
                    {RecordAutocomplete()}
                </ReferenceInput>
                <ReferenceInput label="Город" source="cityId" reference="cities" alwaysOn perPage={10}>
                    {RecordAutocomplete()}
                </ReferenceInput>
                <SelectInput label="Тип заказа" source="orderType" emptyText="Все" alwaysOn choices={[
                        {id: 'framer', name: 'Framer'},
                        {id: 'offline', name: 'Оффлайн'}
                ]} />
                <SelectArrayInput label="Статус" source="state" alwaysOn choices={[
                    {id: "draft", name: 'Расчет (draft)'},
                    {id: "calc_errors", name: 'Ошибки построения (calc_errors)'},
                    {id: "needs_recalc", name: 'Необходим пересчет (needs_recalc)'},
                    {id: "awaiting_payment", name: 'Ожидает оплаты (awaiting_payment)'},
                    {id: "paid", name: 'Оплачен (paid)'},
                    {id: "booking", name: 'Передаем в производство (booking)'},
                    {id: "booked", name: 'Забронирован (booked)'},
                    {id: "cancel_requested", name: 'Запрос отмены (cancel_requested)'},
                    {id: "cancelled", name: 'Отменен (cancelled)'},
                    {id: "producing", name: 'В производстве (producing)'},
                    {id: "production_error", name: 'Ошибка производства (production_error)'},
                    {id: "ready_for_pickup", name: 'Готов к отгрузке (ready_for_pickup)'},
                    {id: "produced__shipping_to_client", name: 'Готов, ожидается доставка (produced_shipping_to_client)'},
                    {
                        id: "produced__shipping_to_warehouse",
                        name: 'Готов, ожидается доставка на склад (produced_shipping_to_warehouse)'
                    },
                    {id: "shipping_to_client_address", name: 'Доставка по адресу (shipping_to_client_address)'},
                    {id: "shipping_to_warehouse", name: 'Доставка на склад (shipping_to_warehouse)'},
                    {
                        id: "at_warehouse_awaiting_shipping",
                        name: 'На складе, ожидается доставка (at_warehouse_awaiting_shipping)'
                    },
                    {id: "at_warehouse_awaiting_pickup", name: 'На складе, ожидается самовывоз (at_warehouse_awaiting_pickup)'},
                    {id: "completed", name: 'Завершен (completed)'},
                ]}/>
                <SelectArrayInput label="Причина отмены бронирования" source="cancellationReason" alwaysOn
                                  choices={Object.entries(HumanReadableCancellationReason).map(
                                      ([code, humanReadable]) => ({id: code, name: `${humanReadable} (${code})`})
                                  )}/>
                <SelectInput label="Тип доставки" source="shipping" emptyText="Все" alwaysOn choices={[
                    {id: "delivery", name: 'Доставка'},
                    {id: "pickup", name: 'Самовывоз'},
                ]}/>
                <SelectInput label="Прямая доставка" source="direct" emptyText="Все" alwaysOn choices={[
                    {id: true, name: 'Да'},
                    {id: false, name: 'Нет'},
                ]}/>
                <DateInput label="Дата создания от" source="dateFrom" alwaysOn/>
                <DateInput label="Дата создания до" source="dateTo" alwaysOn/>
                <NumberInput label="Стоимость конструкций без наценки от, ₽" source="minConstrAmegaCost" alwaysOn/>
                <NumberInput label="Стоимость конструкций без наценки до, ₽" source="maxConstrAmegaCost" alwaysOn/>
            </Filter>
        )
    }
}

const ListActions = (props) => {
    const {
      className,
      exporter,
      filters,
      maxResults,
      setFilters,
      ...rest
    } = props;
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <Button onClick={() => setFilters({})} label="Сбросить фильтры">
          <FilterList />
        </Button>
        <ExportButton/>
      </TopToolbar>
    );
  };


export const OrderList = ({permissions, ...props}) => (
    <Fragment>
        <List filters={Filters(permissions, props)}
              exporter={permissions?.includes('super_admin') || permissions?.includes('chief_admin') ? props.exporter : false}
              title="Заказы" {...props} actions={<ListActions/>}>
            <Datagrid rowClick="edit">
                <TextField source="state" label="Статус"/>
                <TextField source="id" label="Ид"/>
                <DateField source="createdAt" showTime label="Дата создания"/>
                <DateField source="updatedAt" showTime label="Дата обновления"/>
                <DateField source="deletedAt" showTime label="Дата Удаления"/>
                <DateField source="paidAt" showTime label="Дата оплаты"/>
                <TextField source="orderNumber" label="Номер Framer"/>
                <TextField source="windrawOrderNumber" label="Номер Windraw"/>
                <TextField source="windrawGoodsOrderNumber" label="Номер Windraw на допы"/>
                <BooleanField source="hasGoods" label="Есть допы завода"/>
                {
                    !permissions?.includes('rm_admin') &&
                    <ReferenceField source="partnerId" label="Партнер" reference="partners" link="show">
                        <TextField source="name"/>
                    </ReferenceField>
                }
                {
                    permissions?.includes('rm_admin') &&
                    <TextField source="partner.name" label="Партнер"/>
                }
                <TextField source="city.name" label="Город"/>

                <FunctionField label="Цена клиента" render={record =>
                    <FormattedOrderCost order={record} cost={record.totalCost / 100}/>}/>
                <FunctionField label="Наценка" render={record => `к:${record.constrMarginPercent}% д:${record.goodsMarginPercent}%`}/>
                <FunctionField label="Цена субдилера" render={record => record.partner?.isSubdealershipAvailable === true
                    ? <FormattedOrderCost order={record} cost={record.totalAmegaCostToDisplay / 100}/>
                    : '—'}/>
                <FunctionField label="Цена завода" render={record =>
                    <FormattedOrderCost order={record} cost={record.totalAmegaCost / 100}/>}/>
                <FunctionField label="Цена допов" render={record =>
                    <FormattedOrderCost order={record} cost={record.totalAmegaGoodsCost / 100}/>}/>
                    
                <ShowButton/>
            </Datagrid>
        </List>
    </Fragment>
)

const numbersToWeekdays = weekdays => (weekdays) ? weekdays.split(',').map(_ => numbersToWeekdaysMap[_]) : []

export const OrderShow = ({permissions, ...props}) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label="Основное">
                <OrderGetOffer label="Скачать КП"/>
                {
                    !permissions?.includes('rm_admin') &&
                    <OrderToDev label="Копировать заказ на дев"/>
                }
                {
                    !permissions?.includes('rm_admin') &&
                    <OrderToProd label="Копировать заказ себе"/>
                }
                <TextField source="state" label="Статус"/>
                {
                    !permissions?.includes('rm_admin') &&
                    <FunctionField label="Причина отмены бронирования"
                                   render={record => {
                                       const code = record.cancellationReason
                                       const humanReadable = HumanReadableCancellationReason[code]
                                       return `${humanReadable} (${code})`
                                   }}/>
                }
                {
                    !permissions?.includes('rm_admin') &&
                    <FunctionField label="Статус бронирования"
                                   render={record => {
                                       const code = record.paymentState
                                       const humanReadable = HumanReadablePaymentState[code]
                                       return `${humanReadable} (${code})`
                                   }}/>
                }
                {
                    !permissions?.includes('rm_admin') &&
                    <TextField source="id" label="Ид"/>
                }
                <DateField source="createdAt" showTime label="Дата создания"/>
                <DateField source="paidAt" showTime label="Дата оплаты"/>
                <DateField source="updatedAt" showTime label="Дата последнего обновления"/>
                <DateField source="lastCalculatedAt" showTime label="Дата последнего пересчета"/>
                <DateField source="deletedAt" showTime label="Дата Удаления"/>
                {
                    !permissions?.includes('rm_admin') &&
                    <OrderRestore label="Восстановить заказ"/>
                }
                {
                    !permissions?.includes('rm_admin') &&
                    <ReferenceField source="dealerId" label="Контрагент" reference="dealers" link="show">
                        <TextField source="name"/>
                    </ReferenceField>
                }
                {
                    !permissions?.includes('rm_admin') &&
                    <ReferenceField source="partnerId" label="Партнер" reference="partners" link="show">
                        <TextField source="name"/>
                    </ReferenceField>
                }
                {
                    permissions?.includes('rm_admin') &&
                    <ReferenceField source="dealerId" label="Контрагент" reference="dealers" link={false}>
                        <TextField source="name"/>
                    </ReferenceField>
                }
                {
                    permissions?.includes('rm_admin') &&
                    <TextField source="partner.name" label="Партнер"/>
                }
                {
                    !permissions?.includes('rm_admin') &&
                    <ReferenceField source="filialId" label="Филиал" reference="filials" link="show">
                        <TextField source="name"/>
                    </ReferenceField>
                }
                {
                    !permissions?.includes('rm_admin') &&
                    <ReferenceField source="ownerId" label="Пользователь" reference="users" link="show">
                        <TextField source="username"/>
                    </ReferenceField>
                }
                <TextField source="city.name" label="Город"/>
                {
                    (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                    <OrderCreateAtWindrawAnd1C label="Создать в Windraw и 1C"/>
                }
                {
                    (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                    <OrderCreateAt1C label="Создать в 1С"/>
                }
                {
                    (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                    <OrderBook label="Забронировать"/>
                }
                {
                    (permissions?.includes('super_admin') || permissions?.includes('chief_admin') || permissions?.includes('rm_admin')) &&
                    <CreatePreorder label="Создать предварительный заказ в Windraw"/>
                }
                <FunctionField label="Наценка на конструкции" render={record => `${record.constrMarginPercent}%`}/>
                <FunctionField label="Наценка на допы" render={record => `${record.goodsMarginPercent}%`}/>
                <TextField source="orderNumber" label="Номер Framer"/>
                <TextField source="windrawOrderNumber" label="Номер Windraw"/>
                <TextField source="windrawGoodsOrderNumber" label="Номер Windraw на допы"/>
                <TextField source="windrawPreOrderNumber" label="Номер Windraw предварительного заказа"/>
                <BooleanField source="hasGoods" label="Есть допы завода"/>
                <DateField source="shippingDate" label="Дата доставки"/>
                {
                    (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                    <OrderSetShippingDate label="Установить дату доставки"/>
                }
                {
                    !permissions?.includes('rm_admin') &&
                    <OrderUnbookAtWindraw label="Запрос на отмену бронирования"/>
                }
                {
                    !permissions?.includes('rm_admin') &&
                    <OrderUnbook label="Отменить бронирование"/>
                }
                {
                    !permissions?.includes('rm_admin') &&
                    <OrderAutoUnbook label="Авторазбронировать заказ"/>
                }
                {
                    !permissions?.includes('rm_admin') &&
                    <OrderRestoreUnbookAtWindraw label="Восстановить статус заказа"/>
                }
                {
                    (permissions?.includes('super_admin') || permissions?.includes('chief_admin') || permissions?.includes('rm_admin')) &&
                    <CompleteOrder/>
                }
                

                <FunctionField label="Цена клиента" render={record =>
                    <FormattedOrderCost order={record} cost={record.totalCost / 100}/>}/>
                <FunctionField label="Цена субдилера" render={record => record.partner?.isSubdealershipAvailable === true
                    ? <FormattedOrderCost order={record} cost={record.totalAmegaCostToDisplay / 100}/>
                    : '—'}/>
                <FunctionField label="Цена завода" render={record =>
                    <FormattedOrderCost order={record} cost={record.totalAmegaCost / 100}/>}/>
                <FunctionField label="Цена допов" render={record =>
                    <FormattedOrderCost order={record} cost={record.totalAmegaGoodsCost / 100}/>}/>
                <FunctionField label="Цена завода без скидки" render={record =>
                    <FormattedOrderCost order={record} cost={record.totalAmegaCostWithoutDiscount / 100}/>}/>
                <NumberField source="discount.percent" label="Процент скидки"/>
                <FunctionField label="Скидка" render={record => record.calcDiscounts.ttl / 100}/>
                <NumberField source="totalSquare" label="Площадь"/>
                <NumberField source="totalWeight" label="Вес"/>
                <NumberField source="marginPercent" label="Процент маржи"/>
                <FunctionField label="Тип доставки" render={
                    record => record.shippingType === "pickup" ? 'Самовывоз' :
                        record.shippingType === "delivery" ? "Доставка" : "Не выбран"}/>
                <FunctionField label="Адрес доставки" render={record =>
                    record.shippingAddress == null ? `` :
                        `${record.shippingAddress.region}, ${record.shippingAddress.city}, ` +
                        `${record.shippingAddress.street}, ${record.shippingAddress.building}, ` +
                        `${record.shippingAddress.zipcode}`}/>
                <FunctionField label="Склад самовывоза" render={
                    record => record.warehouse == null ? `` : `${record.warehouse.name}`
                }/>
                <TextField source="shippingComment" label="Комментарий к доставке"/>
                <TextField source="contactPerson.name" label="Контактное лицо"/>
                <TextField source="contactPerson.phone" label="Контактный номер телефона"/>
                <BooleanField source="isDirectShipping" label="Прямая доставка"/>
                <FunctionField label="Цена доставки" render={record => record.shippingCost / 100}/>
                <NumberField source="shippingFloor" label="Этаж доставки"/>
                <FunctionField label="Стоимость подъема на этаж" render={record => record.shippingToFloorCost / 100}/>
                {
                    !permissions?.includes('rm_admin') &&
                    <DateField source="mountingDate" label="Дата монтажа"/>
                }
                <BooleanField source="readonly" label="Неизменяем"/>
                {
                    !permissions?.includes('rm_admin') &&
                    <OrderSetGOSTParam/>
                }
                {
                    !permissions?.includes('rm_admin') &&
                    <OrderSetPPGOSTParam/>
                }
                <FunctionField label="Все стеклопакеты по ГОСТ" render={record => record.hasGostSign ? "Да" : "Нет"}/>
                {
                    (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                    <OrderSetHandleParams label="Установить параметры ручки"/>
                }
                {
                    !permissions?.includes('rm_admin') &&
                    <BooleanField source="forceBatchRecalc" label="Полный пересчет заказа"/>
                }
                {
                    !permissions?.includes('rm_admin') &&
                    <OrderBatchRecalc label="Установить полный пересчет заказа"/>
                }
                {
                    !permissions?.includes('rm_admin') &&
                    <OrderFullRecalc label="Установить принудительный пересчет заказа"/>
                }
                {
                    (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                    <RecalcOrder/>
                }
                {
                    !permissions?.includes('rm_admin') &&
                    <OrderProductsSeparationStep label="Изменить количество одновременно пересчитываемых изделий для заказа"/>
                }
                <FunctionField label="Принудительный тип доставки" render={record => record.forceShippingType
                    ? record.forceShippingType === "delivery"
                        ? "Доставка по адресу"
                        : "Доставка на склад"
                    : "Не указано"}/>
                {
                    !permissions?.includes('rm_admin') &&
                    <ForceShippingType/>
                }

                <FunctionField label="Принудительный адрес доставки" render={record => record.forceShippingAddress
                    ? Address.parse(record.forceShippingAddress).toString()
                    : "Не указано"}/>
                {
                    !permissions?.includes('rm_admin') &&
                    <ForceShippingAddress/>
                }

                <FunctionField label="Принудительная прямая доставка"
                               render={record => typeof record.forceDirectShipping === 'boolean' ? record.forceDirectShipping ? 'Да' : 'Нет' : 'Не указано'}/>
                {
                    !permissions?.includes('rm_admin') &&
                    <ForceDirectShipping/>
                }

                <ReferenceField source="forceWarehouseId" label="Принудительный склад доставки" reference="warehouses" link={false}>
                    <TextField source="name"/>
                </ReferenceField>
                {
                    !permissions?.includes('rm_admin') &&
                    <ForceWarehouse/>
                }

                <FunctionField label="Принудительная цена доставки (₽)"
                               render={record => typeof record.forceShippingCost === 'number' ? record.forceShippingCost / 100 : 'Не указано'}/>
                {
                    !permissions?.includes('rm_admin') &&
                    <ForceShippingCost/>
                }

                <FunctionField label="Принудительные дни недели доставки"
                               render={record => numbersToWeekdays(record.forceWeekdays).join(', ') || 'Не указано'}/>
                {
                    !permissions?.includes('rm_admin') &&
                    <ForceWeekdays/>
                }

                <TextField source="comment" label="Комментарий к заказу"/>
                <BooleanField source="showCommentInKp" label="Показывать комментарий в КП"/>
            </Tab>
            <Tab label="Скидки">
                <OrderCosts/>
                {
                    !permissions?.includes('rm_admin') &&
                    <OrderFactoryDiscounts/>
                }
                {
                    (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                    <OrderSetDiscounts label="Установить скидки"/>
                }
                <NumberField source="calcDiscounts.st" label="Расчитан со скидкой на конструкции(в МСК)/стандарт(в Перми)"/>
                <NumberField source="calcDiscounts.gls" label="Расчитан со скидкой на стеклопакеты"/>
                <NumberField source="calcDiscounts.gds" label="Расчитан со скидкой на допы"/>
                <NumberField source="calcDiscounts.met" label="Расчитан со скидкой на метал"/>
                <NumberField source="calcDiscounts.nst" label="Расчитан со скидкой на нестандарт"/>
                <NumberField source="calcDiscounts.lam" label="Расчитан со скидкой на ламинацию"/>
                <NumberField source="calcDiscounts.wAl" label="Расчитан со скидкой на теплый алюминий"/>
                <NumberField source="calcDiscounts.cAl" label="Расчитан со скидкой на холодный алюминий"/>
            </Tab>
            <Tab label="Конструкции"><ConstructionsTab/></Tab>
            <Tab label="Доп. материалы"><GoodsTab/></Tab>
        </TabbedShowLayout>
    </Show>
)

function OrderCosts(props) {
    return (
        <React.Fragment>
            <SimpleShowLayout>
                <FunctionField label="Цена завода без скидки"
                               render={record => record.totalAmegaCostWithoutDiscount / 100}/>
                <FunctionField label="Цена завода" render={record => record.totalAmegaCost / 100}/>
                <FunctionField label="Цена конструкций" render={record => record.totalAmegaConstrCost / 100}/>
                <FunctionField label="Цена допов" render={record => record.totalAmegaGoodsCost / 100}/>
                <FunctionField label="Цена завода на стандарт" render={record => record.orderCostStandard / 100}/>
                <FunctionField label="Цена завода на нестандарт" render={record => record.orderCostNonstandard / 100}/>
                <FunctionField label="Цена завода на ламинацию" render={record => record.orderCostLamination / 100}/>
                <FunctionField label="Цена завода на теплый алюминий" render={record => record.orderCostWarmAl / 100}/>
                <FunctionField label="Цена завода на холодный алюминий"
                               render={record => record.orderCostColdAl / 100}/>
                <FunctionField label="Цена завода на метал" render={record => record.orderCostMetal / 100}/>
                <FunctionField label="Цена завода на стеклопакеты" render={record => record.orderCostGlass / 100}/>
            </SimpleShowLayout>
        </React.Fragment>
    )
}

const formatDiscounts = type => record => {
    const factory = record.factoryDiscounts[type]
    const manual = record.manualDiscounts[type]
    return manual === 0 ? `${factory.toFixed(2)}%` : `${manual.toFixed(2)}%`
}

function OrderFactoryDiscounts(props) {
    return (
        <React.Fragment>
            <SimpleShowLayout>
                <FunctionField label="Скидка на конструкции(в МСК)/стандарт(в Перми)" render={formatDiscounts('st')}/>
                <FunctionField label="Скидка на стеклопакеты" render={formatDiscounts('gls')}/>
                <FunctionField label="Скидка на допы" render={formatDiscounts('gds')}/>
                <FunctionField label="Скидка на метал" render={formatDiscounts('met')}/>
                <FunctionField label="Скидка на нестандарт" render={formatDiscounts('nst')}/>
                <FunctionField label="Скидка на ламинацию" render={formatDiscounts('lam')}/>
                <FunctionField label="Скидка на теплый алюминий" render={formatDiscounts('wAl')}/>
                <FunctionField label="Скидка на холодный алюминий" render={formatDiscounts('cAl')}/>
            </SimpleShowLayout>
        </React.Fragment>
    )
}
