import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useNotify} from 'react-admin';
import {postJson} from '../../api/postJson'
import {DiscountsEditor} from '../../components/discountsEditor'
import {FactoryCostCalculator} from './factoryCostCalculator'
import TextField from '@material-ui/core/TextField'

export default function OrderSetDiscounts(props) {
    const {record, label} = props;
    const notify = useNotify()
    const [discounts, setDiscounts] = useState(record.manualDiscounts)
    const [comment, setComment] = React.useState('')

    const handleSubmit = () => {
        if (!comment) {
            notify(`Не заполнено поле 'Комментарий'`, 'error');
            return;
        }
        postJson(`/admin/orders/${record.id}/discounts`, {...discounts, comment: comment})
            .then(() => {
                Object.assign(record.manualDiscounts, discounts) // Copy values to record if request succeeded (and values were updated in the backend).
                notify('Заказ успешно обновлен!')
            })
            .catch((e) => {
                console.error(e)
                notify(`Ошибка обновления скидок`, 'error')
            })
            .finally(() => window.history.back())
    }

    return (
        <DiscountsEditor label={label} discounts={discounts} onChange={setDiscounts} onSubmit={handleSubmit}>
            <FactoryCostCalculator record={record} discounts={discounts} onChange={setDiscounts}/>
            <TextField
                margin="dense"
                label="Комментарий"
                type="text"
                onChange={(e) => setComment(e.target.value)}
                fullWidth
                required
            />
        </DiscountsEditor>
    )
}

OrderSetDiscounts.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};
