import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import {
    SimpleList,
    List,
    Datagrid,
    TextField,
    DateField,
    ShowButton,
    Filter,
    TextInput,
    BooleanField,
    NumberField,
    SimpleShowLayout,
    Show,
    SelectInput,
    ReferenceField,
    ReferenceInput,
    TopToolbar,
    ExportButton,
    Button,
    sanitizeListRestProps
} from 'react-admin';
import { FilterList } from "@material-ui/icons";
import FilialChangeCity from "./filialChangeCity";
import FilialExternalFields from "./filialExternalFields";
import {RecordAutocomplete} from '../components/recordAutocomplete'
import FilialVilaterm from './filialChangeVilaterm';
import FilialImpost from './filialChangeImpost';

const FilialFilter = props => (
    <Filter {...props}>
        <TextInput label="Поиск" source="q" alwaysOn/>
        <SelectInput label="Внешний" source="isExternal" emptyText="Все" alwaysOn choices={[
            { id: true, name: 'Да' },
            { id: false, name: 'Нет' },
        ]}/>
        <SelectInput label="Основной" source="isMain" emptyText="Все" alwaysOn choices={[
            { id: true, name: 'Да' },
            { id: false, name: 'Нет' },
        ]}/>
        <ReferenceInput label="Партнер" source="partnerId" reference="partners" alwaysOn perPage={10}>
            {RecordAutocomplete()}
        </ReferenceInput>
    </Filter>
)

const ListActions = (props) => {
    const {
      className,
      exporter,
      filters,
      maxResults,
      setFilters,
      ...rest
    } = props;
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <Button onClick={() => setFilters({})} label="Сбросить фильтры">
          <FilterList />
        </Button>
        <ExportButton/>
      </TopToolbar>
    );
  };

export const FilialsList = ({permissions, ...props}) => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List actions={<ListActions/>} filters={<FilialFilter/>} exporter={permissions?.includes('super_admin') || permissions?.includes('chief_admin') ? props.exporter : false} title="Филиалы" {...props}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.partnerId}
                />
            ) : (
                <Datagrid>
                    <TextField source="id" label="Ид"/>
                    <DateField source="createdAt" showTime label="Дата создания"/>
                    <DateField source="deletedAt" showTime label="Дата Удаления"/>
                    <TextField source="name" label="Наименование"/>
                    <BooleanField source="isExternal" label="Внешний"/>
                    <BooleanField source="isMain" label="Основной"/>
                    <NumberField source="margin.percent" label="Наценка (%)"/>
                    <ReferenceField source="partnerId" label="Партнер" reference="partners" link="show" >
                        <TextField source="id"/>
                    </ReferenceField>
                    <TextField source="city.name" label="Город"/>
                    <ShowButton/>
                </Datagrid>
            )}
        </List>
    );
};

export const FilialShow = ({permissions, ...props}) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" label="Ид"/>
            <DateField source="createdAt" showTime label="Дата создания"/>
            <DateField source="updatedAt" showTime label="Дата последнего обновления"/>
            <DateField source="deletedAt" showTime label="Дата Удаления"/>
            <TextField source="name" label="Наименование"/>
            <BooleanField source="isMain" label="Основной"/>
            <ReferenceField source="partnerId" label="Партнер" reference="partners" link="show" >
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="city.name" label="Город"/>
            {
                (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                <FilialChangeCity label="Сменить город"/>
            }
            <BooleanField source="isExternal" label="Внешний"/>
            <FilialExternalFields/>
            <BooleanField source="isVilatermShouldBeSet" label="Принудительная установка вилатерма на все заказы"/>
            {
                (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                <FilialVilaterm label="Включить/отключить принудительную установку вилатерма"/>
            }
            <BooleanField source="isMetalKreplenieImposta" label="Принудительная установка металлической пятки импоста"/>
            {
                (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                <FilialImpost label="Включить/отключить принудительную установку импоста"/>
            }
        </SimpleShowLayout>
    </Show>
);
