import {
    Datagrid,
    DateField,
    ExportButton,
    Filter,
    List,
    sanitizeListRestProps,
    SelectInput,
    Show,
    ShowButton,
    SimpleShowLayout,
    TextField,
    TopToolbar,
    useListContext
} from "react-admin";
import React, {cloneElement} from "react";
import WindrawServicesStopService from "./windrawservicesStopService";
import WindrawServicesStopCalcs from "./windrawservicesStopCalcs";
import WindrawServicesStartCreateService from "./windrawservicesStartCreateService";
import WindrawServicesStartDbUpdate from "./windrawservicesStartDbUpdate";

const WindrawServicesFilter = props => (
    <Filter {...props}>
        <SelectInput label="Завод" source="factoryId" emptyText="Все" alwaysOn choices={[
            {id: 3, name: 'Пермь'},
            {id: 4, name: 'Электросталь'},
        ]}/>
        <SelectInput label="Тип" source="type" emptyText="Все" alwaysOn choices={[
            {id: "calc", name: 'Расчеты'},
            {id: "create", name: 'Создание'},
            {id: "book", name: 'Бронирование'},
            {id: "balancer", name: 'Балансер'},
        ]}/>
    </Filter>
)

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
            <WindrawServicesStopCalcs/>
            <WindrawServicesStartCreateService/>
            <WindrawServicesStartDbUpdate/>
        </TopToolbar>
    );
};


export const WindrawServicesList = ({permissions, ...props}) => (
    <List actions={<ListActions/>} title="Windraw services"
          exporter={permissions?.includes('super_admin') ? props.exporter : false}
          filters={<WindrawServicesFilter/>} {...props}>
        <Datagrid>
            <TextField source="id" label="CorrelationID"/>
            <TextField source="serviceType" label="Тип сервиса"/>
            <DateField source="createdAt" showTime label="Дата старта"/>
            <DateField source="lastHeartbeatAt" showTime label="Дата последнего пинга"/>
            <TextField source="groupId" label="Имя группы"/>
            <TextField source="factoryId" label="Завод"/>
            <ShowButton/>
        </Datagrid>
    </List>
);

export const WindrawServicesShow = ({permissions, ...props}) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" label="CorrelationID"/>
            <TextField source="serviceType" label="Тип сервиса"/>
            <DateField source="createdAt" showTime label="Дата старта"/>
            <DateField source="lastHeartbeatAt" showTime label="Дата последнего пинга"/>
            <TextField source="groupId" label="Имя группы"/>
            <TextField source="factoryId" showTime label="Завод"/>
            <WindrawServicesStopService label="Остановить сервис"/>
        </SimpleShowLayout>
    </Show>
);