import TextField from '@material-ui/core/TextField'
import React, {ChangeEvent} from 'react'
import {Discounts} from '../../components/discountsEditor'

interface Props {
    record: any
    discounts: Discounts
    onChange: (value: Discounts) => void
}

export const FactoryCostCalculator = ({record, discounts, onChange}: Props) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        let newCost = parseInt(event.target.value) * 100 - record.totalAmegaGoodsCost;

        let factoryId = record.city.factoryId;

        const newDiscounts = {...discounts};

        let orderRedundantCostConstr = 0;
        if (record.orderCostStandard !== 0 && record.calcDiscounts.st === 0) {
            orderRedundantCostConstr += record.orderCostStandard;
        }
        if (record.orderCostNonstandard !== 0 && record.calcDiscounts.nst === 0 && factoryId === 3) {
            orderRedundantCostConstr += record.orderCostNonstandard;
        }
        if (record.orderCostLamination !== 0 && record.calcDiscounts.lam === 0 && factoryId === 3) {
            orderRedundantCostConstr += record.orderCostLamination;
        }
        if (record.orderCostWarmAl !== 0 && record.calcDiscounts.wAl === 0 && factoryId === 3) {
            orderRedundantCostConstr += record.orderCostWarmAl;
        }
        if (record.orderCostColdAl !== 0 && record.calcDiscounts.cAl === 0 && factoryId === 3) {
            orderRedundantCostConstr += record.orderCostColdAl;
        }
        if (record.orderCostMetal !== 0 && record.calcDiscounts.met === 0 && factoryId === 3) {
            orderRedundantCostConstr += record.orderCostMetal;
        }
        if (record.orderCostGlass !== 0 && record.calcDiscounts.gls === 0 && factoryId === 4) {
            orderRedundantCostConstr += record.orderCostGlass;
        }
        if (record.orderCostOther !== 0) {
            orderRedundantCostConstr += record.orderCostOther;
        }

        if (record.orderMoskitkasCostStandard !== 0) {
            orderRedundantCostConstr += record.orderMoskitkasCostStandard
        }
        if (record.orderMoskitkasCostGlass !== 0) {
            orderRedundantCostConstr += record.orderMoskitkasCostGlass
        }
        if (record.orderMoskitkasCostMetal !== 0) {
            orderRedundantCostConstr += record.orderMoskitkasCostMetal
        }
        if (record.orderMoskitkasCostNonstandard !== 0) {
            orderRedundantCostConstr += record.orderMoskitkasCostNonstandard
        }
        if (record.orderMoskitkasCostLamination !== 0) {
            orderRedundantCostConstr += record.orderMoskitkasCostLamination
        }
        if (record.orderMoskitkasCostWarmAl !== 0) {
            orderRedundantCostConstr += record.orderMoskitkasCostWarmAl
        }
        if (record.orderMoskitkasCostColdAl !== 0) {
            orderRedundantCostConstr += record.orderMoskitkasCostColdAl
        }

        if (record.orderCostStandard !== 0 && record.calcDiscounts.st > 0) {
            newDiscounts.st = 100 -
                ((record.orderCostStandard - record.orderMoskitkasCostStandard) * (newCost - orderRedundantCostConstr) /
                    (record.totalAmegaConstrCost - orderRedundantCostConstr)) /
                ((record.orderCostStandard - record.orderMoskitkasCostStandard) * 100 / (100 - record.calcDiscounts.st)) * 100
        }

        if (record.orderCostNonstandard !== 0 && record.calcDiscounts.nst > 0 && factoryId === 3) {
            newDiscounts.nst = 100 -
                ((record.orderCostNonstandard - record.orderMoskitkasCostNonstandard) * (newCost - orderRedundantCostConstr) /
                    (record.totalAmegaConstrCost - orderRedundantCostConstr)) /
                ((record.orderCostNonstandard - record.orderMoskitkasCostNonstandard) * 100 / (100 - record.calcDiscounts.nst)) * 100
        }

        if (record.orderCostLamination !== 0 && record.calcDiscounts.lam > 0 && factoryId === 3) {
            newDiscounts.lam = 100 -
                ((record.orderCostLamination - record.orderMoskitkasCostLamination) * (newCost - orderRedundantCostConstr) /
                    (record.totalAmegaConstrCost - orderRedundantCostConstr)) /
                ((record.orderCostLamination - record.orderMoskitkasCostLamination) * 100 / (100 - record.calcDiscounts.lam)) * 100
        }

        if (record.orderCostWarmAl !== 0 && record.calcDiscounts.wAl > 0 && factoryId === 3) {
            newDiscounts.wAl = 100 -
                ((record.orderCostWarmAl - record.orderMoskitkasCostWarmAl) * (newCost - orderRedundantCostConstr) /
                    (record.totalAmegaConstrCost - orderRedundantCostConstr)) /
                ((record.orderCostWarmAl - record.orderMoskitkasCostWarmAl) * 100 / (100 - record.calcDiscounts.wAl)) * 100
        }

        if (record.orderCostColdAl !== 0 && record.calcDiscounts.cAl > 0 && factoryId === 3) {
            newDiscounts.cAl = 100 -
                ((record.orderCostColdAl - record.orderMoskitkasCostColdAl) * (newCost - orderRedundantCostConstr) /
                    (record.totalAmegaConstrCost - orderRedundantCostConstr)) /
                ((record.orderCostColdAl - record.orderMoskitkasCostColdAl) * 100 / (100 - record.calcDiscounts.cAl)) * 100
        }

        if (record.orderCostMetal !== 0 && record.calcDiscounts.met > 0 && factoryId === 3) {
            newDiscounts.met = 100 -
                ((record.orderCostMetal - record.orderMoskitkasCostMetal) * (newCost - orderRedundantCostConstr) /
                    (record.totalAmegaConstrCost - orderRedundantCostConstr)) /
                ((record.orderCostMetal - record.orderMoskitkasCostMetal) * 100 / (100 - record.calcDiscounts.met)) * 100
        }

        if (record.orderCostGlass !== 0 && record.calcDiscounts.gls > 0 && factoryId === 4) {
            newDiscounts.gls = 100 -
                ((record.orderCostGlass - record.orderMoskitkasCostGlass) * (newCost - orderRedundantCostConstr) /
                    (record.totalAmegaConstrCost - orderRedundantCostConstr)) /
                ((record.orderCostGlass - record.orderMoskitkasCostGlass) * 100 / (100 - record.calcDiscounts.gls)) * 100
        }

        onChange(newDiscounts)
    }

    return (
        <TextField
            defaultValue={record.totalAmegaCost / 100}
            margin="dense"
            label="Необходимая стоимость завода"
            type="number"
            style={{minWidth: '20em'}}
            onChange={handleChange}
        />
    )
}
