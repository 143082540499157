export const webAndMobile = {id: 'webAndMobile', name: 'Веб и моб. приложение', showOnWeb: true, showOnMobile: true}
export const webOnly = {id: 'webOnly', name: 'Веб', showOnWeb: true, showOnMobile: false}
export const mobileOnly = {id: 'mobileOnly', name: 'Моб. приложение', showOnWeb: false, showOnMobile: true}
export const alertDevices = [webAndMobile, webOnly, mobileOnly]

export const findAlertDevice = ({showOnWeb, showOnMobile}) => {
    if (showOnWeb && showOnMobile) {
        return webAndMobile
    }
    if (showOnWeb) {
        return webOnly
    }
    if (showOnMobile) {
        return mobileOnly
    }
    return null
}
