import React from "react";
import {Button, useNotify, useRefresh} from "react-admin";
import {API_ADMIN} from "../../const";
import PropTypes from "prop-types";

export default function AmegaEmployeeAdd() {
    const notify = useNotify();
    const refresh = useRefresh();

    const handleClick = () => {
        const body = {
            name: '',
            catalog1C: '',
            referralCode: '',
            cityId: 6,
        }

        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_ADMIN + '/amegaemployees',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }),
                body: JSON.stringify(body)
            });

        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    response.text().then(json => {
                        notify(`${json}`);
                    })
                } else {
                    notify('Сотрудник добавлен')
                }
            })
            .catch(() => {
                notify(`Ошибка выполнения запроса`, 'error')
            })
            .finally(() => {
                refresh()
            });
    };

    return (
        <Button variant="outlined" color="primary" onClick={handleClick} label="Создать"/>
    )
}

AmegaEmployeeAdd.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};
