export const GUID = (): string => {
  const S4 = (): string => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  return `${S4()}${S4()}-${S4()}-${S4()}-${S4()}-${S4()}${S4()}${S4()}`
}

let index = -1

export const getIndex = (): number => {
  return index--
}
