import {
    Datagrid, Filter, List,
    SelectInput, Show, ShowButton, SimpleShowLayout, TextField,
    TextInput
} from "react-admin";
import React from "react";
import AmqpHandlerRestart from "./amqpHandlerRestart";

const AmqpHandlerFilter = props => (
    <Filter {...props}>
        <TextInput label="Поиск" source="q" alwaysOn/>
        <SelectInput label="Завод" source="factoryId" emptyText="Все" alwaysOn choices={[
            { id: "3", name: 'Пермь' },
            { id: "4", name: 'Электросталь' },
        ]}/>
    </Filter>
)


export const AmqpHandlerList = ({permissions, ...props}) => (
    <List title="Amqp handlers" exporter={permissions?.includes('super_admin') ? props.exporter : false} filters={<AmqpHandlerFilter/>} {...props}>
        <Datagrid>
            <TextField source="name" label="Имя"/>
            <TextField source="factoryID" showTime label="Завод"/>
            <ShowButton/>
        </Datagrid>
    </List>
);

export const AmqpHandlerShow = ({permissions, ...props}) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" label="Имя"/>
            <TextField source="factoryID" showTime label="Завод"/>
            {
                (permissions?.includes('super_admin')) &&
                <AmqpHandlerRestart label="Перезапуск"/>
            }
        </SimpleShowLayout>
    </Show>
);