import Button from '@material-ui/core/Button'
import React, { useRef, useState } from 'react'
import MapUploader from '../services/mapUpload'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Dialog from '@material-ui/core/Dialog'
import FormGroup from '@material-ui/core/FormGroup'
import FormLabel from '@material-ui/core/FormLabel'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

export default function MapUpload () {
    const uploader = new MapUploader()

    const [file, setFile] = useState(null)
    const [errorMessage, setErrorMessage] = useState(null)
    const filePickerRef = useRef()

    const changeFile = e => {
        const files = e.target.files
        if (files.length === 1) {
            setFile(files[0])
        } else {
            setFile(null)
        }
    }

    const clearSelectedFile = () => {
        setFile(null)
        filePickerRef.current.value = null
    }

    const uploadFile = async () => {
        if (file !== null) {
            const content = await file.text()

            try {
                const resp = await uploader.uploadYandexGeoJson(content)
                if (resp.errors?.length > 0) {
                    setErrorMessage('Не все геозоны и склады были обновлены. ' +
                        'Убедитесь, что геозоны и склады имеют корректные ID.')
                }

                clearSelectedFile()
            } catch (e) {
                setErrorMessage('Не удалось обновить карту. Проверьте соединение с сервером.')
            }
        } else {
            console.error('File was not selected')
        }
    }

    const clearErrorMessage = () => setErrorMessage(null)

    return (
        <FormGroup>
            <FormLabel>Обновить карту геозон и складов в БД</FormLabel>
            <input id="x" type="file" accept=".geojson" ref={filePickerRef} onChange={changeFile}/>
            <ButtonGroup>
                <Button onClick={clearSelectedFile} disabled={file === null}>Очистить</Button>
                <Button onClick={uploadFile} disabled={file === null}>Обновить</Button>
            </ButtonGroup>
            <Dialog open={errorMessage !== null}>
                <DialogTitle>Произошла ошибка</DialogTitle>
                <DialogContent>{errorMessage}</DialogContent>
                <DialogActions>
                    <Button onClick={clearErrorMessage}>Закрыть</Button>
                </DialogActions>
            </Dialog>
        </FormGroup>
    )
}
