import React from "react";
import {useNotify} from "react-admin";
import {API_ADMIN} from "../const";
import FetchRequest from "../fetchRequest";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from "prop-types";

export default function DealerUndoRefund(props) {
    const {record, label} = props;
    let [open, setOpen] = React.useState(false);
    const notify = useNotify();
    let refund = {id: 0};
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_ADMIN + '/dealers/' + record.id + '/refund/' + refund.id + '/undo',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }),
            });
        FetchRequest('Ошибка отмены возрата средств', request, notify, () => {
            window.history.back();
            setOpen(false)
        });
    };

    const style = {
        minHeight: 300,
    };

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Возврат средств</DialogTitle>
                <DialogContent style={style}>
                    <AsyncSelect
                        autoFocus
                        margin="dense"
                        label="Возврат"
                        placeholder="Выберите возврат из списка"
                        defaultOptions loadOptions={getPromiseOptionsForDealerId(record.id)}
                        onChange={(option) => {
                            refund = option
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleClick} color="primary">
                        Отменить выбранный возврат
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

function getPromiseOptionsForDealerId (id) {
    let promiseOptions = (inputValue) =>
        new Promise((resolve, reject) => {
            const access_token = localStorage.getItem('access_token');
            const request = new Request(API_ADMIN + '/dealers/' + id + '/refund',
                {
                    method: 'GET',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${access_token}`
                    }),
                });
            fetch(request).then(response => {
                response.json().then(json => {
                    json.forEach((value, index) => {
                        let currency = "руб."
                        if (value.accountType === 2) {
                            currency = "фант."
                        }
                        value.label = value.createdAt.split('T')[0] + '; ' + value.amount / 100 + ` ${currency}; ` + value.comment;
                        value.value = value.id;
                        json[index] = value;
                    })
                    resolve(json)
                })
            })
        });
    return promiseOptions
}

DealerUndoRefund.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};
