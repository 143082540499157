import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {useNotify} from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {API_ADMIN} from '../const'
import FetchRequest from "../fetchRequest";
import {HumanReadableCancellationReason} from "./cancellationReasons";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Select from "react-select";

export default function OrderUnbookAtWindraw(props) {
    const {record, label} = props;
    let [open, setOpen] = React.useState(false);
    const notify = useNotify();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };

    let reason = 'other'

    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_ADMIN + '/orders/'+record.id+'/unbookatwindraw',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}` }),
                body: reason
            });

        FetchRequest('Ошибка разбронирования', request, notify, ()=> {
            window.history.back();
            setOpen(false);
        })
    };

    const style = {
        minHeight: 350,
    };

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    disabled={record.state === 'draft' || record.state === 'needs_recalc' || record.state === 'calc_errors' || record.state === 'cancelled'}
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Запрос на отмену бронирования</DialogTitle>
                <DialogContent style={style}>
                    <Select
                        defaultValue={{value: reason, label: `${HumanReadableCancellationReason[reason]} (${reason})`}}
                        autoFocus
                        options={Object.entries(HumanReadableCancellationReason).map(
                                ([code, humanReadable]) => ({value: code, label: `${humanReadable} (${code})`})
                            )}
                        margin="dense"
                        label="Причина возврата"
                        onChange={(option) => {
                            reason = option.value
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleClick} color="primary">
                        Запросить отмену заказа
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

OrderUnbookAtWindraw.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};
