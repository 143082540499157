import React, { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { postJson } from '../../api/postJson'
import { get } from '../../api/get'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

export const ForceWarehouse = ({ record }) => {
    const [open, setOpen] = React.useState(false)
    const [warehouseId, setWarehouseId] = React.useState(record.forceWarehouseId ?? '')
    const notify = useNotify()

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const [availableWarehouses, setAvailableWarehouses] = useState([])

    useEffect(() => {
        get(`/admin/orders/${record.id}/warehouses`)
            .then(resp => setAvailableWarehouses(JSON.parse(resp)))
            .catch(e => {
                console.error(e)
                notify('Ошибка получения списка складов', 'error')
            })
    }, [record.id, notify])

    const handleClick = () => {
        postJson(`/admin/orders/${record.id}/force/warehouse`, { value: warehouseId })
            .then(resp => notify(resp))
            .catch(resp => notify(resp.toString(), 'error'))
            .finally(() => {
                handleClose()
                document.location.reload()
            })
    }

    const handleClear = () => {
        postJson(`/admin/orders/${record.id}/force/warehouse`, { value: null })
            .then(resp => notify(resp))
            .catch(resp => notify(resp.toString(), 'error'))
            .finally(() => {
                handleClose()
                document.location.reload()
            })
    }

    const handleChange = e => setWarehouseId(parseInt(e.target.value))

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary" onClick={handleOpen}>
                Установить принудительный склад доставки
            </Button>

            <Dialog aria-labelledby="simple-dialog-title" open={open} onClose={handleClose}>
                <DialogTitle id="simple-dialog-title">Установить принудительный склад доставки</DialogTitle>

                <DialogContent>
                    <InputLabel id="select-warehouse-id-label">Склад доставки</InputLabel>
                    <Select
                        labelId="select-warehouse-id-label"
                        id="select-warehouse-id"
                        value={warehouseId}
                        label="Склад"
                        onChange={handleChange}
                    >
                        {availableWarehouses.filter(_ => _.name).map(({ id, name }, index) =>
                            <MenuItem key={index} value={id}>{name}</MenuItem>
                        )}
                    </Select>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClick}>Подтвердить</Button>
                    <Button onClick={handleClear}>Очистить</Button>
                    <Button onClick={handleClose}>Отмена</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
