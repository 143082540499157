export default function FetchRequest(errorPrefix, request, notify, callback) {
    fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                response.text().then(text => {
                    notify(`${errorPrefix}: ${text}`);
                })
            } else {
                response.text().then(text => {
                    notify(`${text}`);
                })
            }
        })
        .catch((e) => {
            notify(`Ошибка выполнения запроса`, 'error');
        })
        .finally(() => {
            callback();
        });
}