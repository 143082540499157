/* eslint-disable */

export const stringify = (object: any): string => {
  const replacer = (key: string, value: any): any => {
    if (key.startsWith('_')) {
      return undefined
    } else {
      return value
    }
  }

  return JSON.stringify(object, replacer)
}

export function getClone<T>(obj: any): T {
  return JSON.parse(JSON.stringify(obj)) as T
}
