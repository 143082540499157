import React from 'react'
import {Button, useNotify, useRefresh} from 'react-admin'
import {API_ADMIN} from '../const'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import DialogActions from '@material-ui/core/DialogActions'
import {makeStyles} from '@material-ui/core/styles'
import {PushReceiverTypes} from "./pushReceiverTypes";
import {Tooltip} from "@material-ui/core";

export default function UpdatePush(props) {
    const {record, label} = props

    const useStyles = makeStyles({
        option: {
            fontSize: 15,
            '& > span': {
                marginRight: 10,
                fontSize: 18
            }
        }
    })

    const notify = useNotify()
    const refresh = useRefresh()
    const classes = useStyles()

    let [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleClick = () => {
        const access_token = localStorage.getItem('access_token')
        const request = new Request(API_ADMIN + '/marketingnotifications/' + record.id,
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }),
                body: JSON.stringify(record, ['name', 'description', 'date', 'image', 'preview', 'receiver', 'receiverId'])
            })

        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    response.text().then(json => {
                        notify(`${json}`)
                    })
                } else {
                    notify('Пуш обновлен!')
                }
            })
            .catch(() => {
                notify(`Ошибка выполнения запроса`, 'error')
            })
            .finally(() => {
                refresh()
                setOpen(false)
            })
    }

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary" onClick={handleClickOpen} label={label}/>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Создать маркетинговый пуш</DialogTitle>
                <DialogContent>
                    <TextField
                        defaultValue={record.name}
                        autoFocus
                        margin="dense"
                        label="Заголовок"
                        type="text"
                        onChange={(event) => record.name = event.target.value}
                    />
                    <TextField
                        defaultValue={record.description}
                        autoFocus
                        margin="dense"
                        label="Описание"
                        type="text"
                        fullWidth
                        onChange={(event) => record.description = event.target.value}
                    />
                    <Tooltip title="Дата" arrow>
                        <TextField
                            defaultValue={record.date}
                            type="date"
                            autoFocus
                            margin="dense"
                            label="Дата"
                            onChange={(event) => record.date = event.target.value}
                        />
                    </Tooltip>
                    <Tooltip title="Картинка" arrow>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Картинка"
                            type="file"
                            fullWidth
                            onChange={(event) => {
                                getBase64(event.target.files[0], (result) => {
                                    record.image = result
                                });
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Превью" arrow>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="Превью"
                            type="file"
                            fullWidth
                            onChange={(event) => {
                                getBase64(event.target.files[0], (result) => {
                                    record.preview = result
                                });
                            }}
                        />
                    </Tooltip>
                    <Autocomplete
                        defaultValue={PushReceiverTypes[0]}
                        style={{width: 300}}
                        options={PushReceiverTypes}
                        classes={{
                            option: classes.option
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.name}
                            </React.Fragment>
                        )}
                        onChange={(event, newValue) => {
                            record.receiver = newValue.id
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Ограничение получателей"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password' // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    <TextField
                        defaultValue={record.receiverId}
                        autoFocus
                        margin="dense"
                        label="ИД получателя"
                        type="number"
                        onChange={(event) => record.receiverId = parseInt(event.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" label="Отмена"/>
                    <Button onClick={handleClick} color="primary" label="Сохранить"/>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

UpdatePush.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object
}
