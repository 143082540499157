import React from 'react';
import UserIcon from '@material-ui/icons/Group';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import StoreIcon from '@material-ui/icons/Store';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import BallotIcon from '@material-ui/icons/Ballot';
import PaymentIcon from '@material-ui/icons/Payment';
import UserParamsIcon from '@material-ui/icons/ChromeReaderMode';
import UserActionsIcon from '@material-ui/icons/TouchApp';
import AddAlert from '@material-ui/icons/AddAlert'
import DoneAllIcon from '@material-ui/icons/DoneAll';
import {Admin, Resource} from 'react-admin';
import {AdminAuditEventsList} from './adminAudit/events'
import {PartnerShow, PartnersList} from './partners/partners';
import {UserList, UserShow} from './users/users';
import {FilialShow, FilialsList} from './filials/fillials';
import {OrderList, OrderShow} from './orders/orders';
import {DealerList, DealerShow} from './dealers/dealers';
import authProvider from './authProvider';
import {PartnerDiscountsList} from "./partners/partnerDiscounts";
import {superDataProvider} from './dataProvider';
import {AccountOperationList} from "./accountOperations";
import MyLayout from './myLayout';
import {UserParamsList} from "./userParams";
import {UserActionsList} from "./userActions";
import MapUpload from './maps/components/mapUpload'
import MapsIcon from '@material-ui/icons/Map'
import ApartmentIcon from '@material-ui/icons/Apartment'
import PrecisionManufacturingIcon from '@material-ui/icons/Category';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import {AmegaEmployeeList, AmegaEmployeeShow} from "./amegaEmployees/components/amegaEmployees";
import {AlertList, AlertShow} from './alerts/alerts'
import {AmqpHandlerList, AmqpHandlerShow} from "./amqpHandlers/amqpHandlers";
import {WindrawServicesList, WindrawServicesShow} from "./windrawservices/windrawservices";
import {MarketingPushNotifications, PushShow} from "./marketingPushNotifications/marketingPushNotifications";
import {GoodsUpdatesList} from "./goodsUpdates/goodsUpdates";

const not_rm_admin = localStorage.getItem('permissions')?.includes('super_admin') ||
    localStorage.getItem('permissions')?.includes('chief_admin') ||
    localStorage.getItem('permissions')?.includes('common_admin') ||
    localStorage.getItem('permissions')?.includes('elohina_admin')


const App = () => (
    <Admin
        layout={MyLayout}
        dataProvider={superDataProvider}
        authProvider={authProvider}
    >
        <Resource
            name="users"
            options={{label: 'Пользователи'}}
            icon={UserIcon}
            list={UserList}
            show={UserShow}
        />

        <Resource
            name="partners"
            options={{label: 'Партнеры'}}
            icon={AccountBalanceIcon}
            list={PartnersList}
            show={PartnerShow}
        />

        <Resource
            name="dealers"
            options={{label: 'Контрагенты'}}
            icon={BusinessCenterIcon}
            list={DealerList}
            show={DealerShow}
        />

        <Resource
            name="amegaemployees"
            options={{label: 'Сотрудники Амега'}}
            icon={ApartmentIcon}
            list={not_rm_admin && AmegaEmployeeList}
            show={AmegaEmployeeShow}
        />

        <Resource
            name="filials"
            options={{label: 'Филиалы'}}
            icon={StoreIcon}
            list={not_rm_admin && FilialsList}
            show={FilialShow}
        />

        <Resource
            name="orders"
            options={{label: 'Заказы'}}
            list={OrderList}
            show={OrderShow}
        />

        <Resource
            name="partnerdiscounts"
            options={{label: 'Скидки партнеров'}}
            icon={BallotIcon}
            list={not_rm_admin && PartnerDiscountsList}
        />

        <Resource
            name="accountoperations"
            options={{label: 'Платежи'}}
            icon={PaymentIcon}
            list={not_rm_admin && AccountOperationList}
        />

        <Resource
            name="useractions"
            options={{label: 'Действия в заказе'}}
            icon={UserActionsIcon}
            list={not_rm_admin && UserActionsList}
        />

        <Resource
            name="maps"
            options={{label: 'Карты'}}
            icon={MapsIcon}
            list={not_rm_admin && MapUpload}
        />

        <Resource
            name="alerts"
            options={{label: 'Оповещения'}}
            icon={AddAlert}
            list={not_rm_admin && AlertList}
            show={AlertShow}
        />

        <Resource
          name="adminauditevents"
          options={{label: 'Аудит действий'}}
          icon={VerifiedUserIcon}
          list={
            (localStorage.getItem('permissions')?.includes('chief_admin') // Show only if chief or super admin.
              || localStorage.getItem('permissions')?.includes('super_admin'))
            && AdminAuditEventsList
        }
          show={AlertShow}
        />

        <Resource
            name="userparams"
            options={{label: 'Пользовательские параметры'}}
            icon={UserParamsIcon}
            list={localStorage.getItem('permissions')?.includes('super_admin') && UserParamsList}
        />

        <Resource
            name="amqphandlers"
            options={{label: 'Amqp Handlers'}}
            icon={DoneAllIcon}
            list={localStorage.getItem('permissions')?.includes('super_admin') && AmqpHandlerList}
            show={AmqpHandlerShow}
        />

        <Resource
            name="windrawservices"
            options={{label: 'Windraw services'}}
            icon={PrecisionManufacturingIcon}
            list={localStorage.getItem('permissions')?.includes('super_admin') && WindrawServicesList}
            show={WindrawServicesShow}
        />

        <Resource
            name="marketingnotifications"
            options={{label: 'Маркетинговые пуши'}}
            list={not_rm_admin && MarketingPushNotifications}
            show={PushShow}
        />

        <Resource
            name="goodsupdates"
            options={{label: 'Обновления допов'}}
            list={(localStorage.getItem('permissions')?.includes('chief_admin') // Show only if chief or super admin.
                || localStorage.getItem('permissions')?.includes('super_admin')) && GoodsUpdatesList}
        />

        {/* Used as references in other resources. Not shown in sidebar */}
        <Resource name="factories"/>
        <Resource name="constructions"/>
        <Resource name="warehouses"/>
        <Resource name="cities"/>
    </Admin>
);
export default App;
