import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {useNotify} from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {API_ADMIN} from '../const'
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";

export default function RegisterRegionalManager(props) {
    const {label} = props;
    let [open, setOpen] = React.useState(false);
    let newUser = {
        "login": "",
        "email": "",
        "password": "",
        "confirmPassword": ""
    }
    const notify = useNotify();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };
    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_ADMIN + '/users/register/rmadmin',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }),
                body: JSON.stringify(newUser),
            });
        fetch(request)
            .then(response => {
                if (response.status !== 200) {
                    response.json().then(json => {
                        notify(`Ошибка создания пользователя: ${json}`, 'error');
                    })
                } else {
                    response.text().then(json => {
                        notify(`${json}`);
                    })
                }
            })
            .catch((e) => {
                notify(`Ошибка выполнения запроса`, 'error')
            })
            .finally(() => {
                window.history.back();
                setOpen(false)
            });
    };
    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog open={open}>
                <DialogTitle id="simple-dialog-title">Создать регионального менеджера</DialogTitle>
                <DialogContent>
                    <TextField
                        defaultValue=""
                        margin="dense"
                        label="Логин"
                        type="text"
                        onChange={(event)=>{newUser["login"] = event.target.value}}
                    />
                    <br/>
                    <TextField
                        defaultValue=""
                        margin="dense"
                        label="Email"
                        type="email"
                        onChange={(event)=>{newUser["email"] = event.target.value}}
                    />
                     <br/>
                    <TextField
                        defaultValue=""
                        margin="dense"
                        label="Пароль"
                        type="password"
                        onChange={(event)=>{newUser["password"] = event.target.value}}
                    />
                     <br/>
                    <TextField
                        defaultValue=""
                        margin="dense"
                        label="Повтор пароля"
                        type="password"
                        onChange={(event)=>{newUser["confirmPassword"] = event.target.value}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleClick} color="primary">
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

RegisterRegionalManager.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};