const getFilterValue = (state, source, resource, field) => {
    let value = undefined;

    if (!state || !source) return value;

    const filterJSON =
        state.router &&
        state.router.location &&
        state.router.location.query &&
        state.router.location.query.filter;

    if (filterJSON) {
        let filter = JSON.parse(decodeURIComponent(filterJSON));

        let id = filter && filter[source];

        if (id !== undefined) {
            if (!resource) {
                value = id;
            } else {
                const data =
                    state.admin &&
                    state.admin.resources &&
                    state.admin.resources[resource] &&
                    state.admin.resources[resource].data;

                if (data) {
                    value = data[id];
                    if (field) {
                        value = value[field];
                    }
                }
            }
        }
    }

    return value;
};

export default getFilterValue;