import React from 'react'
import { API_ADMIN } from '../const'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { useNotify } from 'react-admin'

export const CreatePreorder = (props) => {
    const { record, label } = props
    let [open, setOpen] = React.useState(false)
    const notify = useNotify()

    const handleClickOpen = () => setOpen(true)

    const handleClose = () => {
        setOpen(false)
    }

    const handleClick = () => {
        setOpen(false)
        notify('Запрос отправлен, подождите...')

        window.open(`${API_ADMIN}/orders/${record.id}/xml?t=${localStorage['access_token']}`)
        window.history.back()
    }

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary" style={{ margin: '0.5em 0' }}
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">{label}?</DialogTitle>
                <List>
                    <ListItem button onClick={handleClick}>
                        <ListItemText primary="Да"/>
                    </ListItem>
                    <ListItem button onClick={handleClose}>
                        <ListItemText primary="Нет"/>
                    </ListItem>
                </List>
            </Dialog>
        </React.Fragment>
    )
}
