import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {useNotify} from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {API_ADMIN} from '../const'
import {DialogContent, TextField} from "@material-ui/core";

export default function SendTestPush(props) {
    const {record, label} = props;
    let [open, setOpen] = React.useState(false);
    const notify = useNotify();

    const [tokens] = useState({
        tokens: ''
    })

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };
    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_ADMIN + '/marketingnotifications/'+record.id+'/sendtest',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}` }),
                body: JSON.stringify(tokens)
            });
        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    response.json().then(json => {
                        notify(`Ошибка отправки пуша: ${json}`);
                    })
                } else {
                    response.text().then(json => {
                        notify(`${json}`);
                    })
                }
            })
            .catch((e) => {
                notify(`Ошибка выполнения запроса`, 'error')
            })
            .finally(() => {
                window.history.back();
                setOpen(false)
            });
    };
    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    disabled={record.state === 'send'}
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Действительно отправить пуш?</DialogTitle>
                <DialogContent>
                    <TextField
                        defaultValue={tokens.tokens}
                        autoFocus
                        margin="dense"
                        label="Список ид устройств"
                        type="text"
                        fullWidth
                        onChange={(event) => tokens.tokens = event.target.value}/>
                </DialogContent>
                <List>
                    <ListItem button onClick={handleClick}>
                        <ListItemText primary="Да"/>
                    </ListItem>
                    <ListItem button onClick={handleClose}>
                        <ListItemText primary="Нет"/>
                    </ListItem>
                </List>
            </Dialog>
        </React.Fragment>
    )
}

SendTestPush.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};
