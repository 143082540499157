import React, {useRef} from "react";
import {Button, useNotify} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import {API_ADMIN} from "../const";
import TextField from "@material-ui/core/TextField";

export default function WindrawServicesStartDbUpdate(props) {
    let [open, setOpen] = React.useState(false);
    const notify = useNotify();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };

    let factoryId = 3;
    const dbUpdateOutputRef = useRef(null);

    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        const startUpdateRequest = new Request(API_ADMIN + '/windrawservices/startdb/' + factoryId,
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }),
            });

        const getOutputRequest = new Request(API_ADMIN + '/windrawservices/getdboutput/' + factoryId,
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }),
            });

        const sleep = (milliseconds) => {
            return new Promise(resolve => setTimeout(resolve, milliseconds))
        }

        fetch(startUpdateRequest).then(response => {
            if (response.status < 200 || response.status >= 300) {
                response.text().then(json => {
                    notify(`${json}`);
                })
            } else {
                response.text().then(json => {
                    notify(`${json}`);
                })
            }
        }).catch((e) => {
            notify(`Ошибка выполнения запроса`, 'error')
        });

        let p2 = new Promise(async function (resolve, reject) {
            let ok = true;
            let counter = 0;
            function handleOutputResp (res) {
                res.text().then(function (text) {
                    counter++;
                    if (text != null && dbUpdateOutputRef.current.value != null) {
                        dbUpdateOutputRef.current.value = text;
                        if (text.includes("Db update finished") && counter > 2) {
                            ok = false;
                            resolve();
                        }
                    }
                })
            }

            while (ok) {
                fetch(getOutputRequest).then(handleOutputResp)

                await sleep(500)
            }
        })

        Promise.all([p2]).then(()=>{
        })
    };

    const handlePerm = () => {
        factoryId = 3;
        handleClick();
    };

    const handleMsk = () => {
        factoryId = 4;
        handleClick();
    };

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    onClick={handleClickOpen} label="Запустить процесс обновления БД"/>
            <Dialog aria-labelledby="simple-dialog-title" open={open} fullWidth maxWidth={"lg"}>
                <DialogTitle id="simple-dialog-title">Выберите завод для обновления БД:</DialogTitle>
                <div>
                    <Button onClick={handlePerm} label="Пермь"/>
                    <Button onClick={handleMsk} label="МСК"/>
                    <Button onClick={handleClose} label="Выход"/>
                </div>
                <TextField
                    fullWidth
                    inputRef={dbUpdateOutputRef}
                    label="output"
                    multiline={true}
                    variant="outlined"
                    size="small"
                    InputProps={{
                        readOnly: true,
                    }}
                />
            </Dialog>
        </React.Fragment>
    )
}

WindrawServicesStartDbUpdate.propTypes = {
    label: PropTypes.string
};