import React, {useCallback} from 'react'
import PropTypes from 'prop-types'
import {useNotify} from 'react-admin'
import {postJson} from '../api/postJson'
import {discountEditorFieldStyle, DiscountsEditor} from '../components/discountsEditor'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core'

export default function AdditionalDiscounts(props) {
    const {record, writeAccess} = props
    const notify = useNotify()
    const [profileName, setProfileName] = React.useState('')
    const [discounts, setDiscounts] = React.useState(record.AdditionalDiscounts)
    const [comment, setComment] = React.useState('')

    const submit = useCallback((discounts, comment) => {
        if (!comment) {
            notify(`Не заполнено поле 'Комментарий'`, 'error');
            return;
        }
        postJson(`/admin/partners/${record.id}/setadditionaldiscounts`, {...discounts, comment: comment})
            .then(() => {
                Object.assign(record.AdditionalDiscounts, discounts)
                notify('Партнер успешно обновлен!')
            })
            .catch((e) => {
                console.error(e)
                notify(`Ошибка обновления скидок`, 'error')
            })
            .finally(() => {
                window.history.back();
            })
        }, [record, notify]);

    const handleSubmit = useCallback(() => submit(discounts, comment), [submit, discounts, comment])

    const handleChangeBaseDiscounts = useCallback(d =>
        setDiscounts({
            ...discounts,
            baseDiscounts: d,
        }), [setDiscounts, discounts])

    const handleChangeFramerPoints = useCallback(event =>
        setDiscounts({
            ...discounts,
            additionalDiscountFramerPoints: parseFloat(event.target.value),
        }), [setDiscounts, discounts])

    const handleAddProfileDiscount = useCallback(() => {
        if (!profileName) {
            notify('Необходимо ввести название профиля', 'warning')
            return
        }

        setDiscounts({
            ...discounts,
            profileDiscounts: {
                ...discounts.profileDiscounts,
                [profileName]: {st: 0, nst: 0, lam: 0, wAl: 0, cAl: 0}
            }
        })

        notify(`Профиль ${profileName} успешно добавлен. Для сохранения скидки необходимо изменить значения и нажать "Сохранить"`)
    }, [discounts, profileName, notify])

    const handleDeleteProfileDiscount = useCallback((profileName) => () => {
        const newDiscounts = {...discounts}
        delete newDiscounts.profileDiscounts[profileName]

        setDiscounts(newDiscounts)
        submit(newDiscounts)
    }, [discounts, submit])

    const handleSetProfileDiscounts = useCallback((profile) => (profileDiscounts) => setDiscounts({
        ...discounts,
        profileDiscounts: {
            ...discounts.profileDiscounts,
            [profile]: profileDiscounts,
        }
    }), [discounts, setDiscounts])

    const handleRenameProfile = useCallback(event => setProfileName(event.target.value), [setProfileName])

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Профиль</TableCell>
                            <TableCell>Стандарт</TableCell>
                            <TableCell>Нестандарт</TableCell>
                            <TableCell>Ламинация</TableCell>
                            <TableCell>Теплый алюминий</TableCell>
                            <TableCell>Холодный алюминий</TableCell>
                            <TableCell>Фантики</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>Общая скидка</TableCell>
                            <TableCell>{discounts.baseDiscounts.st}%</TableCell>
                            <TableCell>{discounts.baseDiscounts.nst}%</TableCell>
                            <TableCell>{discounts.baseDiscounts.lam}%</TableCell>
                            <TableCell>{discounts.baseDiscounts.wAl}%</TableCell>
                            <TableCell>{discounts.baseDiscounts.cAl}%</TableCell>
                            <TableCell>{discounts.additionalDiscountFramerPoints}%</TableCell>
                            <TableCell>
                                {writeAccess &&
                                    <DiscountsEditor label='Изменить' discounts={discounts.baseDiscounts} onChange={handleChangeBaseDiscounts} onSubmit={handleSubmit}>
                                        <TextField
                                            style={discountEditorFieldStyle}
                                            defaultValue={discounts.additionalDiscountFramerPoints}
                                            margin="dense"
                                            label="Фантики"
                                            type="number"
                                            onChange={handleChangeFramerPoints}
                                        />
                                        <TextField
                                            margin="dense"
                                            label="Комментарий"
                                            type="text"
                                            onChange={(e) => setComment(e.target.value)}
                                            fullWidth
                                            required
                                        />
                                    </DiscountsEditor>}
                            </TableCell>
                        </TableRow>

                        {discounts.profileDiscounts &&
                            Object.entries(discounts.profileDiscounts).map(([profile, discounts]) => (
                                <TableRow key={profile}>
                                    <TableCell>{profile}</TableCell>
                                    <TableCell>{discounts.st}%</TableCell>
                                    <TableCell>{discounts.nst}%</TableCell>
                                    <TableCell>{discounts.lam}%</TableCell>
                                    <TableCell>{discounts.wAl}%</TableCell>
                                    <TableCell>{discounts.cAl}%</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>
                                        {writeAccess &&
                                            <>
                                                <DiscountsEditor key={profile} label='Изменить' discounts={discounts} onChange={handleSetProfileDiscounts(profile)} onSubmit={handleSubmit}>
                                                <TextField
                                                    margin="dense"
                                                    label="Комментарий"
                                                    type="text"
                                                    onChange={(e) => setComment(e.target.value)}
                                                    fullWidth
                                                    required
                                                />
                                                </DiscountsEditor>
                                                <Button variant="outlined" color="primary" onClick={handleDeleteProfileDiscount(profile)}>Удалить</Button>
                                            </>}
                                    </TableCell>
                                </TableRow>
                            ))}

                        {writeAccess &&
                            <TableRow>
                                <TableCell>
                                    <TextField
                                        style={{...discountEditorFieldStyle, minWidth: '20em'}}
                                        value={profileName}
                                        margin="dense"
                                        type="text"
                                        placeholder='Введите название профиля'
                                        onChange={handleRenameProfile}/>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    <Button variant="outlined" color="primary" onClick={handleAddProfileDiscount}>Добавить скидку для профиля</Button>
                                </TableCell>
                            </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

AdditionalDiscounts.propTypes = {
    record: PropTypes.object,
    writeAccess: PropTypes.bool
};
