import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {useNotify} from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {API_ADMIN} from '../const'
import FetchRequest from "../fetchRequest";

export default function ChangeDealerType(props) {
    const {record, label, permOnly} = props;
    let [open, setOpen] = React.useState(false);
    const notify = useNotify();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };
    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_ADMIN + '/dealers/'+record.id+'/paymenttype',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}` }),
                body: JSON.stringify(record, ['paymentType'])
            });
        FetchRequest('Ошибка смены типа оплаты', request, notify, () => {
            window.history.back();
            setOpen(false)
        });
    };
    const handleSetCard = () => {
        record.paymentType = "card"
        handleClick();
    }
    const handleSetInvoice = () => {
        record.paymentType = "invoice"
        handleClick();
    }
    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    disabled={!record.name.includes("ИП") || (permOnly && record.factoryId !== 3) }
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Выберите тип контрагента:</DialogTitle>
                <List>
                    <ListItem button onClick={handleSetCard}>
                        <ListItemText primary="По карте"/>
                    </ListItem>
                    <ListItem button onClick={handleSetInvoice}>
                        <ListItemText primary="По счету"/>
                    </ListItem>
                    <ListItem button onClick={handleClose}>
                        <ListItemText primary="Отмена"/>
                    </ListItem>
                </List>
            </Dialog>
        </React.Fragment>
    )
}

ChangeDealerType.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};
