import { API_ADMIN } from '../../const'

export default class MapUploader {
    async uploadYandexGeoJson (content) {
        const access_token = localStorage.getItem('access_token')

        const resp = await fetch(`${API_ADMIN}/maps/upload/yandex/geojson`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${access_token}`,
                'Content-Type': 'application/json'
            },
            body: content
        })

        return resp.json()
    }
}
