import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core'
import React from 'react'

export const GoodsTab = ({record}) => {
    const headers = ["Конструкция", "Доп. материал", "Артикул", "Количество", "Пользовательские параметры", "Цена с наценкой", "Цена без наценки"]

    const constructionGoods = record.constructions?.map((c, i) => ({
        name: c.name ? c.name : `Конструкция ${i + 1}`,
        goods: record.goods?.filter(g => g.constructionId === c.id) ?? []
    })) ?? []

    const orderGoods = record.goods?.filter(_ => !_.constructionId) ?? []
    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {headers.map(h => <TableCell key={h} style={{fontWeight: 'bold'}} align="center">{h}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {constructionGoods.map(({name, goods}) =>
                        goods.map(good =>
                            <GoodsTableRow key={good.id} constructionName={name} good={good}/>))}
                    {orderGoods.map((good) =>
                        <GoodsTableRow key={good.id} constructionName="Заказ" good={good}/>)}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const GoodsTableRow = ({constructionName, good}) => (
    <TableRow key={good.id}>
        <TableCell align="center">{constructionName}</TableCell>
        <TableCell align="center">{good.name}</TableCell>
        <TableCell align="center">{good.sku}</TableCell>
        <TableCell align="center">{good.quantity}</TableCell>
        <TableCell align="center">
            {good.attributes.map(a =>
                <p key={a.name}>{a.caption ?? a.name}: {a.enumValues?.find(v => v.value === a.value)?.name ?? a.value}</p>)}
        </TableCell>
        <TableCell align="center">{good.cost / 100} ₽</TableCell>
        <TableCell align="center">
            {good.amegaCost !== good.amegaCostToDisplay
                ? `${good.amegaCost / 100} ₽ / ${good.amegaCostToDisplay / 100} ₽`
                : `${good.amegaCost / 100} ₽`}
        </TableCell>
    </TableRow>
)
