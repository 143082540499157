import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {useNotify, TextField, useRefresh} from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { API_PROMO_CODE_CONFIRM} from '../const'
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

export default function PartnerPromoConfirm(props) {
    const {record, label, id} = props;
    let [open, setOpen] = React.useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };
    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_PROMO_CODE_CONFIRM(id),
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }),
                body: JSON.stringify({code: record.promo.code}),
            });
        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    response.json().then(json => {
                        notify(`Ошибка применения промокода: ${json}`);
                    })
                } else {
                    response.text().then(json => {
                        notify(`${json}`);
                    })
                }
            })
            .catch((e) => {
                notify(`Ошибка выполнения запроса`, 'error')
            })
            .finally(() => {
                setOpen(false)
                refresh()
            });
    };
    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Применить промокод</DialogTitle>
                <DialogContent>Код: {record.promo.code}</DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="error">
                        Отмена
                    </Button>
                    <Button onClick={handleClick} color="primary">
                        Применить
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

PartnerPromoConfirm.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};