import React from 'react'
import { AddressPicker } from '../../maps/components/addressPicker'
import { useNotify } from 'react-admin'
import { postJson } from '../../api/postJson'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { Address } from '../../maps/models/address'

export const ForceShippingAddress = ({ record }) => {
    const [open, setOpen] = React.useState(false)
    const [value, setValue] = React.useState(record.forceShippingAddress
        ? Address.parse(record.forceShippingAddress)
        : record.shippingAddress
            ? Address.parse(record.shippingAddress)
            : new Address('Пермь'))
    const notify = useNotify()

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleClick = () => {
        // When we change address via map or autocomplete input, we fetch dadata address that can be used in backend.
        // When we use value from backend without ever fetching something, it doesn't have dadataAddress field set.
        //
        // Here we check if we haven't fetched something (i.e. we haven't changed the address)
        // because if we proceed and send unchanged value to backend it won't have dadataAddress on it
        // (and several important fields won't be assigned correctly).
        if (!value.dadataAddress) {
            console.log('Force shipping address hasn\'t changed')
            notify('Адрес доставки не был изменен')
            handleClose()
            return
        }

        postJson(`/admin/orders/${record.id}/force/deliveryaddress`,
            { address: value, dadataAddress: value.dadataAddress })
            .then(resp => notify(resp))
            .catch(resp => notify(resp.toString(), 'error'))
            .finally(() => {
                handleClose()
                document.location.reload()
            })
    }

    const handleClear = () => {
        postJson(`/admin/orders/${record.id}/force/deliveryaddress`, {})
            .then(resp => notify(resp))
            .catch(resp => notify(resp.toString(), 'error'))
            .finally(() => {
                handleClose()
                document.location.reload()
            })
    }

    return (
        <>
            <Button variant="outlined" color="primary" onClick={handleOpen}>
                Установить принудительный адрес доставки
            </Button>

            <Dialog aria-labelledby="simple-dialog-title" open={open} onClose={handleClose} fullScreen={true}>
                <DialogTitle id="simple-dialog-title">Установить принудительный адрес доставки</DialogTitle>

                <DialogContent>
                    <AddressPicker value={value} onChange={setValue}/>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClick}>Подтвердить</Button>
                    <Button onClick={handleClear}>Очистить</Button>
                    <Button onClick={handleClose}>Отмена</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
