import React from 'react';
import {
    Show,
    SimpleShowLayout,
    ShowButton,
    List,
    Datagrid,
    TextField,
    NumberField,
    FunctionField,
    DateField,
    Filter,
    TextInput,
    ReferenceInput,
    ReferenceField,
    SelectInput,
    DateInput,
    TopToolbar,
    ExportButton,
    Button,
    sanitizeListRestProps
} from 'react-admin';
import { FilterList } from "@material-ui/icons";

import ChangeDealerType from "./changeDealerType";
import DealerUpdateInfo from "./dealerUpdateInfo";
import DealerRefund from "./dealerRefund";
import DealerUndoRefund from "./dealerUndoRefund";
import {RecordAutocomplete} from '../components/recordAutocomplete'

const DealerFilter = props => (
    <Filter {...props}>
        <TextInput label="Поиск" source="q" alwaysOn/>
        <SelectInput label="Статус подтверждения" source="confirmationStatus" emptyText="Все" alwaysOn choices={[
            { id: "DealerNotConfirmed", name: 'Дилер не подтвержден' },
            { id: "DealerPendingConfirmation", name: 'Дилер ожидает подтверждения' },
            { id: "DealerConfirmed", name: 'Дилер подтвержден' },
        ]}/>
        <SelectInput label="Юр статус" emptyText="Все" source="type" alwaysOn choices={[
            { id: 0, name: 'Юрлицо' },
            { id: 1, name: 'Физлицо' },
        ]}/>
        <ReferenceInput label="Партнер" source="partnerId" reference="partners" alwaysOn perPage={10}>
            {RecordAutocomplete()}
        </ReferenceInput>
        <DateInput label="Дата создания от" source="dateFrom" alwaysOn/>
        <DateInput label="Дата создания до" source="dateTo" alwaysOn/>
    </Filter>
)


const ListActions = (props) => {
    const {
      className,
      exporter,
      filters,
      maxResults,
      setFilters,
      ...rest
    } = props;
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <Button onClick={() => setFilters({})} label="Сбросить фильтры">
          <FilterList />
        </Button>
        <ExportButton/>
      </TopToolbar>
    );
  };


export const DealerList = ({permissions, ...props}) => (
    <List title="Контрагенты" exporter={permissions?.includes('super_admin') || permissions?.includes('chief_admin') ? props.exporter : false} filters={<DealerFilter/>} {...props} actions={<ListActions/>}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="Ид"/>
            <DateField source="deletedAt" showTime label="Дата Удаления"/>
            <TextField source="externalId" label="Код 1С"/>
            <TextField source="name" label="Наименование"/>
            <FunctionField label="Тип оплаты" render={record => record.paymentType === "card" ? `По карте` : record.paymentType === "invoice" ? "По счету" : "Нет значения"} />
            <TextField source="inn" label="Инн"/>
            {
                !permissions?.includes('rm_admin') &&
                <ReferenceField source="partnerId" label="Партнер" reference="partners" link="show">
                    <TextField source="name"/>
                </ReferenceField>
            }
            {
                permissions?.includes('rm_admin') &&
                <TextField source="partner.name" label="Партнер" />
            }
            <ReferenceField source="partner.mainDealerId" label="Головной контрагент" reference="dealers" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="city" label="Город" />
            <TextField source="factoryId" label="Ид завода" />
            <TextField source="confirmationStatus" label="Статус подтверждения"/>
            <FunctionField label="Скидки" render={r => r.factoryId === 4 ? `констр:${r.partner.discount},\nстекл:${r.partner.discountGlass},допы:${r.partner.discountGoods}` : `с:${r.discount},нс:${r.discountNonstandard},л:${r.discountLamination},\n
            та:${r.discountWarmAl},ха:${r.discountColdAl},м:${r.discountMetal}`}/>
            {
                !permissions?.includes('rm_admin') &&
                <ShowButton/>
            }
        </Datagrid>
    </List>
);

export const DealerShow = ({permissions, ...props}) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" label="Ид"/>
            <DateField source="createdAt" showTime label="Дата создания"/>
            <DateField source="updatedAt" showTime label="Дата последнего обновления"/>
            <DateField source="deletedAt" showTime label="Дата Удаления"/>
            <TextField source="externalId" label="Код 1С"/>
            {
                (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                <DealerUpdateInfo label="Обновить информацию"/>
            }
            <TextField source="name" label="Наименование"/>
            <FunctionField label="Тип" render={record => record.type === 0 ? `Юрлицо` : record.type === 1 ? `Физлицо` : "ИП"} />
            <FunctionField label="Тип оплаты" render={record => record.paymentType === "card" ? `По карте` : record.paymentType === "invoice" ? "По счету" : "Нет значения"} />
            {
                (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                <ChangeDealerType label="Сменить тип оплаты"/>
            }
            {
                (permissions?.includes('common_admin')) &&
                <ChangeDealerType label="Сменить тип оплаты" permOnly={true}/>
            }
            <TextField source="inn" label="Инн"/>
            {
                !permissions?.includes('rm_admin') &&
                <ReferenceField source="partnerId" label="Партнер" reference="partners" link="show">
                    <TextField source="name"/>
                </ReferenceField>
            }
            {
                permissions?.includes('rm_admin') &&
                <TextField source="partner.name" label="Партнер" />
            }
            <ReferenceField source="partner.mainDealerId" label="Головной контрагент" reference="dealers" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="city" label="Город" />
            <TextField source="contactInfo.phone" label="Телефоны"/>
            <TextField source="contactInfo.email" label="Почты"/>
            <FunctionField label="Кредитный лимит" render={record => record.creditLimitInCents / 100}/>
            <FunctionField label="Кредитный лимит на фантики" render={record => record.framerCreditLimitInCents / 100}/>
            <FunctionField label="Баланс" render={record => record.account.balance / 100}/>
            <FunctionField label="Баланс в фантиках" render={record => record.framerAccount.balance / 100}/>
            {
                (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                    <DealerRefund label="Возврат средств"/>
            }
            {
                (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                <DealerUndoRefund label="Отмена возврата средств"/>
            }
            <TextField source="factoryId" label="Ид завода"/>
            <TextField source="confirmationStatus" label="Статус подтверждения" />
            <NumberField source="discount" label="Скидка на конструкции/стандарт"/>
            <NumberField source="discountNonstandard" label="Скидка на нестандарт"/>
            <NumberField source="discountLamination" label="Скидка на ламинацию"/>
            <NumberField source="discountWarmAl" label="Скидка на теплый алюминий"/>
            <NumberField source="discountColdAl" label="Скидка на холодный алюминий"/>
            <NumberField source="discountMetal" label="Скидка на металл"/>
            <TextField source="bonusCard.availableScore" label="Доступные бонусы Energy"/>
            <TextField source="bonusCard.potentialScore" label="Потенциальные бонусы Energy"/>
        </SimpleShowLayout>
    </Show>
);
