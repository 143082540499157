import {fetchUtils} from "react-admin";
import simpleRestProvider from "ra-data-simple-rest";
import {API_ADMIN, API_BACKEND} from "./const";

const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }
    const access_token = localStorage.getItem('access_token');
    options.headers.set('Authorization', `Bearer ${access_token}`);
    return fetchUtils.fetchJson(url, options);
};
const adminDataProvider = simpleRestProvider(API_ADMIN, httpClient);
const backendDataProvider = simpleRestProvider(API_BACKEND, httpClient);

const backendResources = ["amqphandlers"]

export const superDataProvider = {
    ...backendDataProvider,
    getList: (resource, params) => {
        if (backendResources.includes(resource)) {
            return backendDataProvider.getList(resource, params)
        } else {
            return adminDataProvider.getList(resource, params)
        }
    },
    getOne: (resource, params) => {
        if (backendResources.includes(resource)) {
            return backendDataProvider.getOne(resource, params)
        } else {
            return adminDataProvider.getOne(resource, params)
        }
    },
    getMany: (resource, params) => {
        if (backendResources.includes(resource)) {
            return backendDataProvider.getMany(resource, params)
        } else {
            return adminDataProvider.getMany(resource, params)
        }
    },
    getManyReference: (resource, params) => {
        if (backendResources.includes(resource)) {
            return backendDataProvider.getManyReference(resource, params)
        } else {
            return adminDataProvider.getManyReference(resource, params)
        }
    },
    create: (resource, params) => {
        if (backendResources.includes(resource)) {
            return backendDataProvider.create(resource, params)
        } else {
            return adminDataProvider.create(resource, params)
        }
    },
    update: (resource, params) => {
        if (backendResources.includes(resource)) {
            return backendDataProvider.update(resource, params)
        } else {
            return adminDataProvider.update(resource, params)
        }
    },
    updateMany: (resource, params) => {
        if (backendResources.includes(resource)) {
            return backendDataProvider.updateMany(resource, params)
        } else {
            return adminDataProvider.updateMany(resource, params)
        }
    },
    delete: (resource, params) => {
        if (backendResources.includes(resource)) {
            return backendDataProvider.delete(resource, params)
        } else {
            return adminDataProvider.delete(resource, params)
        }
    },
    deleteMany: (resource, params) => {
        if (backendResources.includes(resource)) {
            return backendDataProvider.deleteMany(resource, params)
        } else {
            return adminDataProvider.deleteMany(resource, params)
        }
    },
};