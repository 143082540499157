import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {useNotify} from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {API_ADMIN} from '../const'
import FetchRequest from "../fetchRequest";
import DialogActions from "@material-ui/core/DialogActions";

export default function OrderRestoreUnbookAtWindraw(props) {
    const {record, label} = props;
    let [open, setOpen] = React.useState(false);
    const notify = useNotify();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };

    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_ADMIN + '/orders/'+record.id+'/restoreunbookatwindraw',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}` }),
            });

        FetchRequest('Ошибка восстановления статуса', request, notify, ()=> {
            window.history.back();
            setOpen(false);
        })
    };

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    disabled={record.state !== 'cancel_requested'}
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Восстановление статуса заказа</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleClick} color="primary">
                        Восстановить статус
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

OrderRestoreUnbookAtWindraw.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};