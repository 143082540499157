import {AutocompleteInput} from 'react-admin'
import React from 'react'

export const RecordAutocomplete = () => (
    <AutocompleteInput optionText={record => {
        if (!record || !record.id) {
            return 'Все'
        }

        return `${record.id}. ${record.name} ${record.deletedAt ? ' <УДАЛЕН>' : ''}`;
    }}/>
)
