export interface ICoordinatesJSON {
    latitude: number
    longitude: number
}

export class Coordinates {
    static parse(obj: ICoordinatesJSON): Coordinates {
        return new Coordinates(obj.latitude, obj.longitude)
    }

    latitude: number
    longitude: number

    constructor(latitude: number, longitude: number) {
        this.latitude = latitude
        this.longitude = longitude
    }

    equals(coordinates: Coordinates): boolean {
        return this.latitude === coordinates.latitude && this.longitude === coordinates.longitude
    }
}
