import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import PropTypes from 'prop-types'
import React from 'react'
import { useNotify } from 'react-admin'
import { API_ADMIN } from '../const'

export default function OrderSetPPGOSTParam(props) {
    const { record } = props

    const [open, setOpen] = React.useState(false)
    const notify = useNotify()

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleClick = () => {
        const access_token = localStorage.getItem('access_token')
        const request = new Request(API_ADMIN + '/orders/' + record.id + '/setgostmarkparam',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }),
                body: JSON.stringify({
                    value: !record.hasGostMarkSign
                })
            })
        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    response.json().then(json => {
                        notify(`Ошибка добавления параметра: ${json}`)
                    })
                } else {
                    response.text().then(json => {
                        notify(`${json}`)
                    })
                }
            })
            .catch((e) => {
                notify(`Ошибка выполнения запроса`, 'error')
            })
            .finally(() => {
                window.history.back()
                setOpen(false)
            })
    }

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    disabled={!(record.state === 'draft' || record.state === 'needs_recalc' || record.state === 'calc_errors')}
                    onClick={handleClickOpen}>
                {record.hasGostMarkSign
                    ? 'Удалить ПП ГОСТ-маркиратор'
                    : 'ДОБАВИТЬ ПП ГОСТ-маркиратор'}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">
                    {record.hasGostMarkSign
                        ? 'Действительно удалить пользовательский параметр ГОСТ-маркиратор из всех заполнений заказа?'
                        : 'Действительно добавить пользовательский параметр ГОСТ-маркиратор во все заполнения заказа?'}
                </DialogTitle>
                <List>
                    <ListItem button onClick={handleClick}>
                        <ListItemText primary="Да"/>
                    </ListItem>
                    <ListItem button onClick={handleClose}>
                        <ListItemText primary="Нет"/>
                    </ListItem>
                </List>

            </Dialog>
        </React.Fragment>
    )
}

OrderSetPPGOSTParam.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object
}
