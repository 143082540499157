import React, {cloneElement} from 'react'
import {
    AutocompleteInput,
    Datagrid,
    DateField,
    DateInput,
    ExportButton,
    Filter,
    FunctionField,
    List,
    ReferenceInput,
    ReferenceField,
    sanitizeListRestProps,
    SelectInput,
    Show,
    ShowButton,
    SimpleShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    useListContext,
    Button,
} from 'react-admin'
import { FilterList } from "@material-ui/icons"
import AddAlert from './addAlert'
import DeleteAlert from './deleteAlert'
import UpdateAlert from './updateAlert'
import { alertLinkTypes } from "./alertLinkTypes"
import {findAlertDevice, webAndMobile} from './alertDevices'

const AlertFilter = props => (
    <Filter {...props}>
        <TextInput label="Поиск" source="q" alwaysOn/>
        <SelectInput label="Активно" source="active" emptyText="Все" alwaysOn choices={[
            { id: true, name: 'Активные' },
            { id: false, name: 'Неактивные' }
        ]}/>
        <DateInput label="Дата создания от" source="dateFrom" alwaysOn/>
        <DateInput label="Дата создания до" source="dateTo" alwaysOn/>

        <ReferenceInput label="Завод" source="factoryId" reference="factories" alwaysOn perPage={1000}>
            <AutocompleteInput optionText="name" emptyText="Все"/>
        </ReferenceInput>
        <ReferenceInput label="Город" source="cityId" reference="cities" alwaysOn perPage={1000}>
            <AutocompleteInput optionText="name" emptyText="Все"/>
        </ReferenceInput>

        <SelectInput label="Тип ссылки" source="linkType" emptyText="Все" alwaysOn choices={alertLinkTypes}/>
        <SelectInput label="Показывается в веб" source="showOnWeb" emptyText="Все" alwaysOn choices={[{id: true, name: "Да"}, {id: false, name: "Нет"}]}/>
        <SelectInput label="Показывается в моб. приложении" source="showOnMobile" emptyText="Все" alwaysOn choices={[{id: true, name: "Да"}, {id: false, name: "Нет"}]}/>
    </Filter>
)

const AlertActions = props => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        showAddButton,
        setFilters,
        ...rest
    } = props
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        showFilter,
        total
    } = useListContext()

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <Button onClick={() => setFilters({})} label="Сбросить фильтры">
            <FilterList />
            </Button>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button'
            })}
            {
                exporter &&
                <ExportButton
                    disabled={total === 0}
                    resource={resource}
                    sort={currentSort}
                    filterValues={filterValues}
                    maxResults={maxResults}
                />
            }
            <AddAlert label="Создать"/>
        </TopToolbar>
    )
}

export const AlertList = ({ permissions, ...props }) => (
    <List title="Оповещения"
          exporter={permissions?.includes('super_admin') || permissions?.includes('chief_admin') ? props.exporter : false}
          filters={<AlertFilter/>} {...props}
          actions={<AlertActions/>}
          sort={{ field: 'createdAt', order: 'DESC' }}
    >
        <Datagrid rowClick="edit">
            <TextField source="id" label="Ид"/>
            <DateField source="createdAt" showTime label="Дата создания"/>
            <DateField source="deletedAt" showTime label="Дата удаления"/>

            <ReferenceField source="factoryId" label="Завод" reference="factories" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="cityId" label="Город" reference="cities" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <FunctionField label="Активно" render={record => record.active ? 'да' : 'нет'}/>
            <TextField source="title" label="Заголовок"/>
            <TextField source="description" label="Описание"/>

            <TextField source="linkType" label="Тип ссылки"/>
            <TextField source="link" label="Ссылка"/>
            <TextField source="linkText" label="Текст ссылки"/>
            <TextField source="color" label="Цвет (hex)"/>

            <FunctionField label="Устройство" render={record => findAlertDevice(record)?.name ?? webAndMobile.name}/>

            <ShowButton/>
        </Datagrid>
    </List>
)

export const AlertShow = ({ permissions, ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" label="Ид"/>
            <DateField source="createdAt" showTime label="Дата создания"/>
            <DateField source="updatedAt" showTime label="Дата последнего обновления"/>
            <DateField source="deletedAt" showTime label="Дата удаления"/>

            <DeleteAlert/>

            <ReferenceField source="factoryId" label="Завод" reference="factories" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="cityId" label="Город" reference="cities" link="show">
                <TextField source="name"/>
            </ReferenceField>

            <FunctionField label="Активно" render={record => record.active ? 'да' : 'нет'}/>
            <TextField source="title" label="Заголовок"/>
            <TextField source="description" label="Описание"/>

            <TextField source="linkType" label="Тип ссылки"/>
            <TextField source="link" label="Ссылка"/>
            <TextField source="linkText" label="Тип ссылки"/>
            <TextField source="color" label="Цвет (hex)"/>

            <FunctionField label="Устройство" render={record => findAlertDevice(record)?.name ?? webAndMobile.name}/>

            <UpdateAlert label="Изменить"/>
        </SimpleShowLayout>
    </Show>
)
