import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {useNotify, BooleanInput} from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from "@material-ui/core/DialogContent";
import {API_ADMIN} from '../const'
import {Tooltip} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";

export default function OrderSetShippingDate(props) {
    const {record, label} = props;
    let [open, setOpen] = React.useState(false);
    const notify = useNotify();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };

    const [req] = useState({
        date: "",
        force: false
    })

    if (record.shippingDate != null) {
        req.date = record.shippingDate.split('T')[0];
    }

    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_ADMIN + '/orders/'+record.id+'/setshippingdate',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}` }),
                body: JSON.stringify(req),
            });
        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    response.json().then(json => {
                        notify(`Ошибка обновления заказа: ${json}`);
                    })
                } else {
                    response.text().then(json => {
                        notify(`${json}`);
                    })
                }
            })
            .catch((e) => {
                notify(`Ошибка выполнения запроса`, 'error')
            })
            .finally(() => {
                window.history.back();
                setOpen(false)
            });
    };

    const handleClickWihtoutChanges = () => {
        req.force = true
        handleClick()
    }

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    disabled={record.windrawOrderNumber === ""}
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Изменение даты заказа для заказа</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Введите дату доставки для заказа:
                    </DialogContentText>
                    <Tooltip arrow>
                        <TextField
                            defaultValue={req.date}
                            autoFocus
                            margin="dense"
                            label="Дата доставки"
                            type="date"
                            onChange={(event) => {
                                req.date = event.target.value
                            }}
                        />
                    </Tooltip>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleClick} color="primary">
                        Сохранить
                    </Button>
                    <Button onClick={handleClickWihtoutChanges} color="primary">
                        Сохранить без внесения в журнал
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

OrderSetShippingDate.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};