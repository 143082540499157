import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {API_ADMIN} from '../const'
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";
import FetchRequest from "../fetchRequest";
import {useNotify} from "react-admin";

class Refund {
    constructor(dealerId, accountType, comment, amount) {
        this.id = 0;
        this.dealerId = dealerId;
        this.accountType = accountType;
        this.comment = comment;
        this.amount = amount;
    }
}

export default function DealerRefund(props) {
    const {record, label} = props;
    let [open, setOpen] = React.useState(false);
    let refund = new Refund(record.id, 1, "", 0);
    const notify = useNotify();
    const accountTypes = [
        {value: 1, label: 'Рубли'},
        {value: 2, label: 'Фантики'},
    ]

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        refund.amount = refund.amount * 100;
        const request = new Request(API_ADMIN + '/dealers/' + record.id + '/refund',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }),
                body: JSON.stringify(refund),
            });
        FetchRequest('Ошибка возврата средств', request, notify, () => {
            window.history.back();
            setOpen(false)
        });
    };
    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Возврат средств</DialogTitle>
                <DialogContent>
                    <Select
                        defaultValue={accountTypes[0]}
                        autoFocus
                        options={accountTypes}
                        margin="dense"
                        label="Тип счета"
                        onChange={(option) => {
                            refund.accountType = option.value
                        }}
                    />
                    <TextField
                        defaultValue={refund.amount}
                        autoFocus
                        margin="dense"
                        label="Сумма, ₽"
                        type="text"
                        size="medium"
                        onChange={(event) => {
                            refund.amount = event.target.value
                        }}
                        fullWidth
                    />
                    <TextField
                        defaultValue={refund.comment}
                        autoFocus
                        margin="dense"
                        label="Комментарий"
                        type="text"
                        onChange={(event) => {
                            refund.comment = event.target.value
                        }}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleClick} color="primary">
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

DealerRefund.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};
