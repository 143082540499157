import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Address } from '../models/address'
import { KladrManager } from '../services/kladrManager'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { Ymaps } from './ymaps'

export const AddressPicker = ({ value, onChange }) => {
    const [inputValue, setInputValue] = useState('')
    const [suggestions, setSuggestions] = useState([])
    const [coordinatesHardcor, setCoordinatesHardcor] = useState(null)

    const zoom = useMemo(() => {
        let zoom = 1
        if (value.region.length !== 0) {
            zoom = 7
        }
        if (value.city.length !== 0) {
            zoom = 10
        }
        if (value.street.length !== 0) {
            zoom = 14
        }
        if (value.building.length !== 0) {
            zoom = 16
        }
        return zoom
    }, [value.region, value.city, value.street, value.building])

    useEffect(() => {
        KladrManager.getSuggestions(inputValue, null).then(suggestions => setSuggestions(suggestions))
    }, [inputValue])

    const handleInputChange = useCallback((_, value) => { setInputValue(value) }, [setInputValue])
    const handleChange = useCallback((_, value) => {
        const address = Address.parseFromDadataAddress(value.data)
        if (!address.coordinates && coordinatesHardcor) {
            address.coordinates = coordinatesHardcor
        }

        onChange(address)
    }, [onChange, coordinatesHardcor])

    const handleChangeCoords = useCallback(coords => setCoordinatesHardcor(coords), [setCoordinatesHardcor])
    const handleClickCoords = useCallback(async coords => {
        const suggestions = await KladrManager.getSuggestionsByCoords(coords)
        if (suggestions.length === 0) {
            return
        }

        const address = Address.parseFromDadataAddress(suggestions[0].data)
        onChange(address)
        setInputValue(address.toString())
    }, [onChange])
    return <>
        <Autocomplete
            style={{ margin: '1em 0' }}
            options={suggestions}
            getOptionLabel={option => option.value}
            renderInput={(params) => <TextField {...params} label="Адрес доставки"/>}
            filterOptions={_ => _}
            onChange={handleChange}
            onInputChange={handleInputChange}
            inputValue={inputValue}
        />

        <Ymaps zoom={zoom} address={value} onChangeCoords={handleChangeCoords} onClickCoords={handleClickCoords}/>
    </>
}
