import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core'
import React from 'react'

export const ConstructionsTab = ({record}) => {
    const headers = ["Конструкция", "Миниатюра", "Количество", "Цена с наценкой", "Цена без наценки"]

    const constructions = record.constructions.map((c, i) => ({
        ...c,
        name: c.name || `Конструкция ${i + 1}`,
    })) ?? []

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        {headers.map(h => <TableCell key={h} style={{fontWeight: 'bold'}} align="center">{h}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {constructions.map((c) =>
                        <ConstructionsTableRow key={c.id} construction={c}/>)}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const ConstructionsTableRow = ({construction}) => (
    <TableRow>
        <TableCell align="center">{construction.name}</TableCell>
        <TableCell align="center">
            <img src={`data:image/svg+xml;base64, ${construction.image}`} alt="Миниатюра конструкции"/>
        </TableCell>
        <TableCell align="center">{construction.quantity}</TableCell>
        <TableCell align="center">{construction.totalCost / 100} ₽</TableCell>
        <TableCell align="center">
            {construction.amegaCost !== construction.amegaCostToDisplay
                ? `${construction.amegaCost / 100} ₽ / ${construction.amegaCostToDisplay / 100} ₽`
                : `${construction.amegaCost / 100} ₽`}
        </TableCell>
    </TableRow>
)
