import React from 'react'
import { useNotify } from 'react-admin'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { postJson } from '../../api/postJson'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'

export const ForceShippingCost = ({ record }) => {
    const [open, setOpen] = React.useState(false)
    const [value, setValue] = React.useState(record.forceShippingCost ? record.forceShippingCost / 100 : 0)
    const notify = useNotify()

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleClick = () => {
        postJson(`/admin/orders/${record.id}/force/shippingcost`, { value: value * 100 })
            .then(resp => notify(resp))
            .catch(resp => notify(resp.toString(), 'error'))
            .finally(() => {
                handleClose()
                document.location.reload()
            })
    }

    const handleClear = () => {
        postJson(`/admin/orders/${record.id}/force/shippingcost`, { value: null })
            .then(resp => notify(resp))
            .catch(resp => notify(resp.toString(), 'error'))
            .finally(() => {
                handleClose()
                document.location.reload()
            })
    }

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary" onClick={handleOpen}>
                Установить принудительную стоимость доставки
            </Button>

            <Dialog aria-labelledby="simple-dialog-title" open={open} onClose={handleClose}>
                <DialogTitle id="simple-dialog-title">Установить принудительную стоимость доставки</DialogTitle>

                <DialogContent>
                    <TextField type="number" value={value} onChange={e => setValue(parseInt(e.target.value))}/>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClick}>Подтвердить</Button>
                    <Button onClick={handleClear}>Очистить</Button>
                    <Button onClick={handleClose}>Отмена</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
