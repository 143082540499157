import React, {cloneElement} from 'react'
import {
    Datagrid,
    DateField,
    DateInput,
    ExportButton,
    Filter,
    FunctionField,
    List,
    sanitizeListRestProps,
    SelectInput,
    Show,
    ShowButton,
    SimpleShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    ImageField,
    useListContext,
    Button
} from 'react-admin'
import { FilterList } from "@material-ui/icons";
import AddPush from "./addPush";
import {PushReceiverTypes} from "./pushReceiverTypes";
import DeletePush from "./deletePush";
import UpdatePush from "./updatePush";
import SendPush from "./sendPush";
import SendTestPush from "./sendTestPush";

const PushFilter = props => (
    <Filter {...props}>
        <TextInput label="Поиск" source="q" alwaysOn/>
        <SelectInput label="Статус" source="state" emptyText="Все" alwaysOn choices={[
            { id: 'created', name: 'Создан' },
            { id: 'send', name: 'Отправлен' }
        ]}/>
        <DateInput label="Дата создания от" source="dateFrom" alwaysOn/>
        <DateInput label="Дата создания до" source="dateTo" alwaysOn/>
        <SelectInput label="Тип ограничения получателей" source="receiverType" emptyText="Все" alwaysOn choices={PushReceiverTypes}/>
    </Filter>
)

const PushActions = props => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        showAddButton,
        setFilters,
        ...rest
    } = props
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        showFilter,
        total
    } = useListContext()

    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            <Button onClick={() => setFilters({})} label="Сбросить фильтры">
                <FilterList />
            </Button>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button'
            })}
            {
                exporter &&
                <ExportButton
                    disabled={total === 0}
                    resource={resource}
                    sort={currentSort}
                    filterValues={filterValues}
                    maxResults={maxResults}
                />
            }
            <AddPush label="Создать"/>
        </TopToolbar>
    )
}

export const MarketingPushNotifications = ({ permissions, ...props }) => (
    <List title="Маркетинговые пуши"
          exporter={permissions?.includes('super_admin') || permissions?.includes('chief_admin') ? props.exporter : false}
          filters={<PushFilter/>} {...props}
          actions={<PushActions/>}
    >
        <Datagrid rowClick="edit">
            <TextField source="id" label="Ид"/>
            <DateField source="createdAt" showTime label="Дата создания"/>

            <TextField source="date" label="Дата"/>
            <FunctionField label="Статус" render={record => {if (record.state === 'created') return "Создан"; else if (record.state === 'send') return "Отправлен";}}/>
            <TextField source="name" label="Заголовок"/>
            <TextField source="description" label="Описание"/>

            <TextField source="receiver" label="Ограничение получателей"/>
            <TextField source="receiverId" label="ИД получателя"/>

            <ShowButton/>
        </Datagrid>
    </List>
)

export const PushShow = ({ permissions, ...props }) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" label="Ид"/>
            <DateField source="createdAt" showTime label="Дата создания"/>

            <DeletePush/>

            <TextField source="date" label="Дата"/>
            <FunctionField label="Статус" render={record => {if (record.state === 'created') return "Создан"; else if (record.state === 'send') return "Отправлен";}}/>
            <TextField source="name" label="Заголовок"/>
            <TextField source="description" label="Описание"/>

            <ImageField source="image" label="Картинка"/>
            <ImageField source="preview" label="Превью"/>

            <TextField source="receiver" label="Ограничение получателей"/>
            <TextField source="receiverId" label="ИД получателя"/>

            <UpdatePush label="Изменить"/>

            <SendPush label="Отправить пуш"/>
            <SendTestPush label="Отправить тестовый пуш"/>
        </SimpleShowLayout>
    </Show>
)
