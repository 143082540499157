import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {useNotify} from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {API_ADMIN} from '../const'
import FetchRequest from "../fetchRequest";

export default function PartnerVilaterm(props) {
    const {record, label} = props;
    let [open, setOpen] = React.useState(false);
    const notify = useNotify();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };
    const changeVilaterm = (value) => {
        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_ADMIN + '/partners/' + record.id + '/vilaterm',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }),
                body: value,
            });

        FetchRequest('Ошибка смены принудительной установки вилатерма', request, notify, () => {
            window.history.back();
            setOpen(false)
        });
    };
    const turnOn = () => {
        changeVilaterm(true)
    };

    const turnOff = () => {
        changeVilaterm(false)
    }
    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Принудительная установка вилатерма в заказах для {record.name}:</DialogTitle>
                <List>
                    {
                        !record.isVilatermShouldBeSet &&
                        <ListItem button onClick={turnOn}>
                            <ListItemText primary="Включить"/>
                        </ListItem>
                    }
                    {
                        record.isVilatermShouldBeSet &&
                        <ListItem button onClick={turnOff}>
                            <ListItemText primary="Отключить"/>
                        </ListItem>
                    }
                    <ListItem button onClick={handleClose}>
                        <ListItemText primary="Отмена"/>
                    </ListItem>
                </List>

            </Dialog>
        </React.Fragment>
    )
}

PartnerVilaterm.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};