import React from 'react';
import { cloneElement } from 'react';
import UserParamsUpdate from './userParamsUpdate'
import {
    List,
    Datagrid,
    TextField,
    useListContext,
    TopToolbar,
    ExportButton,
    sanitizeListRestProps,
} from 'react-admin';
import TemplatesUpdate from "./templatesUpdate";
import OrdersBatchRecalc from "./orders/ordersBatchRecalc";

const ListActions = (props) => {
    const {
        className,
        exporter,
        filters,
        maxResults,
        ...rest
    } = props;
    const {
        currentSort,
        resource,
        displayedFilters,
        filterValues,
        showFilter,
        total,
    } = useListContext();
    return (
        <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
            {filters && cloneElement(filters, {
                resource,
                showFilter,
                displayedFilters,
                filterValues,
                context: 'button',
            })}
            <ExportButton
                disabled={total === 0}
                resource={resource}
                sort={currentSort}
                filterValues={filterValues}
                maxResults={maxResults}
            />
            <UserParamsUpdate/>
            <TemplatesUpdate/>
            <OrdersBatchRecalc/>
        </TopToolbar>
    );
};

export const UserParamsList = ({permissions, ...props}) => (
    <List actions={<ListActions />} title="Пользовательские параметры" exporter={permissions?.includes('super_admin') ? props.exporter : false} {...props}>
        <Datagrid rowClick="edit">
            <TextField source="id" label="Ид"/>
        </Datagrid>
    </List>
);