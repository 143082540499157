import React from 'react'
import { useNotify } from 'react-admin'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { postJson } from '../../api/postJson'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Checkbox from '@material-ui/core/Checkbox'
import { FormControlLabel, FormGroup } from '@material-ui/core'

export const numbersToWeekdaysMap = {
    '1': 'пн',
    '2': 'вт',
    '3': 'ср',
    '4': 'чт',
    '5': 'пт',
    '6': 'сб',
    '7': 'вс'
}

export const ForceWeekdays = ({ record }) => {
    const [open, setOpen] = React.useState(false)
    const [weekdays, setWeekdays] = React.useState(record.forceWeekdays?.split(',') ?? [])
    const notify = useNotify()

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleClick = () => {
        postJson(`/admin/orders/${record.id}/force/weekdays`, { value: weekdays.join(',') })
            .then(resp => notify(resp))
            .catch(resp => notify(resp.toString(), 'error'))
            .finally(() => {
                handleClose()
                document.location.reload()
            })
    }

    const handleClear = () => {
        postJson(`/admin/orders/${record.id}/force/weekdays`, { value: null })
            .then(resp => notify(resp))
            .catch(resp => notify(resp.toString(), 'error'))
            .finally(() => {
                handleClose()
                document.location.reload()
            })
    }

    const handleChange = key => {
        const index = weekdays.indexOf(key)
        if (index !== -1) {
            setWeekdays([...weekdays.slice(0, index), ...weekdays.slice(index + 1)])
        } else {
            setWeekdays([...weekdays, key].sort())
        }
    }

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary" onClick={handleOpen}>
                Установить принудительные дни доставки
            </Button>

            <Dialog aria-labelledby="simple-dialog-title" open={open} onClose={handleClose}>
                <DialogTitle id="simple-dialog-title">Установить принудительные дни доставки</DialogTitle>

                <DialogContent>
                    <FormGroup>
                        {Object.entries(numbersToWeekdaysMap).map(([key, value]) =>
                            <FormControlLabel
                                key={key} control={<Checkbox checked={weekdays.indexOf(key) !== -1}
                                                             onChange={() => handleChange(key)}/>} label={value}/>
                        )}
                    </FormGroup>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClick}>Подтвердить</Button>
                    <Button onClick={handleClear}>Очистить</Button>
                    <Button onClick={handleClose}>Отмена</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
