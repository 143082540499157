export const SERVER = `https://admin-api.framer.ru` // process.env.SERVER
export const API_LOGIN = `${SERVER}/login`
export const API_ADMIN = `${SERVER}/admin`
export const API_BACKEND = `https://api.framer.ru/v1`
export const PRODUCTS_SEPARATION_STEP = 5

export const YMAPS_API_KEY = '4b7191d7-12f4-452d-a501-2ad1c1faf799'
export const DADATA_API_TOKEN = '1b668d18476de1597dcc6679a71a62a4e9a22600'

export const API_PROMO_CODE_CONFIRM = (id) => `${API_ADMIN}/partners/${id}/usepromo`
