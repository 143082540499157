import React, { useState } from 'react'
import { Button, useNotify, useRefresh } from 'react-admin'
import { cities } from '../cities'
import { API_ADMIN } from '../const'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { factories } from '../factories'
import DialogActions from '@material-ui/core/DialogActions'
import { makeStyles } from '@material-ui/core/styles'
import { alertLinkTypes } from "./alertLinkTypes"
import {alertDevices, findAlertDevice} from './alertDevices'

export default function AddAlert (props) {
    const { label } = props

    const useStyles = makeStyles({
        option: {
            fontSize: 15,
            '& > span': {
                marginRight: 10,
                fontSize: 18
            }
        }
    })

    const notify = useNotify()
    const refresh = useRefresh()
    const classes = useStyles()

    const [record, setRecord] = useState({
        title: '',
        description: '',
        factoryId: 3,
        cityId: 1,
        link: '',
        linkText: '',
        color: '',
        linkType: alertLinkTypes[0].id,
        showOnWeb: true,
        showOnMobile: true
    })

    let [open, setOpen] = React.useState(false)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleClick = () => {
        const access_token = localStorage.getItem('access_token')
        const request = new Request(API_ADMIN + '/alerts',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }),
                body: JSON.stringify(record)
            })

        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    response.text().then(json => {
                        notify(`${json}`)
                    })
                } else {
                    notify('Оповещение создано')
                }
            })
            .catch(() => {
                notify(`Ошибка выполнения запроса`, 'error')
            })
            .finally(() => {
                refresh()
                setOpen(false)
            })
    }

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary" onClick={handleClickOpen} label={label}/>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Создать оповещение</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        value={factories.find(value => value.id === record.factoryId)}
                        style={{ width: 300 }}
                        options={factories}
                        classes={{
                            option: classes.option
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.name}
                            </React.Fragment>
                        )}
                        onChange={(event, newValue) => {
                            setRecord({
                                ...record,
                                factoryId: newValue?.id ?? record.factoryId,
                                cityId: 0
                            })
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Выберите завод"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password' // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    <Autocomplete
                        value={cities.find(value => value.id === record.cityId) ?? null}
                        style={{ width: 300 }}
                        options={cities.filter(x => x.factoryId === record.factoryId)}
                        classes={{
                            option: classes.option
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.name}
                            </React.Fragment>
                        )}
                        onChange={(event, newValue) => setRecord({ ...record, cityId: newValue?.id ?? 0 })}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Выберите город"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password' // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    <TextField
                        value={record.title}
                        autoFocus
                        margin="dense"
                        label="Приложение"
                        type="text"
                        onChange={(event) => setRecord({ ...record, title: event.target.value })}
                    />
                    <TextField
                        value={record.description}
                        autoFocus
                        margin="dense"
                        label="Desktop"
                        type="text"
                        onChange={(event) => setRecord({ ...record, description: event.target.value })}
                    />
                    <Autocomplete
                        value={alertLinkTypes.find(value => value.id === record.linkType)}
                        style={{ width: 300, margin: '1em 0em' }}
                        options={alertLinkTypes}
                        classes={{
                            option: classes.option
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.name}
                            </React.Fragment>
                        )}
                        onChange={(event, newValue) => {
                            setRecord({ ...record, linkType: newValue?.id ?? record.linkType })
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Тип ссылки"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password' // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    <Autocomplete
                        value={findAlertDevice(record)}
                        style={{ width: 300, margin: '1em 0em' }}
                        options={alertDevices}
                        classes={{
                            option: classes.option
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.name}
                            </React.Fragment>
                        )}
                        onChange={(event, newValue) => {
                            setRecord({
                                ...record,
                                showOnWeb: newValue?.showOnWeb ?? record.showOnWeb,
                                showOnMobile: newValue?.showOnMobile ?? record.showOnMobile
                            })
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Тип устройства"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password' // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    <TextField
                        value={record.link}
                        autoFocus
                        margin="dense"
                        label="Ссылка"
                        type="text"
                        onChange={(event) => setRecord({ ...record, link: event.target.value })}
                    />
                    <TextField
                        value={record.linkText}
                        autoFocus
                        margin="dense"
                        label="Текст ссылки"
                        type="text"
                        onChange={(event) => setRecord({ ...record, linkText: event.target.value })}
                    />
                    <TextField
                        value={record.color}
                        autoFocus
                        margin="dense"
                        label="Цвет (hex)"
                        type="text"
                        onChange={(event) => setRecord({ ...record, color: event.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" label="Отмена"/>
                    <Button onClick={handleClick} color="primary" label="Сохранить"/>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

AddAlert.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object
}
