import React from 'react'
import PropTypes from 'prop-types'
import { useNotify, Button } from 'react-admin'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { API_ADMIN } from '../const'

export default function DeleteAlert (props) {
    const { record } = props

    let [open, setOpen] = React.useState(false)

    const notify = useNotify()
    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    let title = "Действительно удалить оповещение?"
    let result = "Оповещение удалено"
    let label = "Удалить (делает оповещение неактивным)"
    if (!record.active) {
        title = "Действительно восстановить оповещение?"
        result = "Оповещение восстановлено"
        label = "Восстановить (делает оповещение активным)"
    }

    const handleClick = () => {
        const access_token = localStorage.getItem('access_token')
        const request = new Request(API_ADMIN + '/alerts/' + record.id,
            {
                method: 'DELETE',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                })
            })
        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    response.json().then(json => {
                        notify(`Ошибка удаления/восстановления оповещения: ${json}`)
                    })
                } else {
                    notify(result)
                }
            })
            .catch(() => {
                notify(`Ошибка выполнения запроса`, 'error')
            })
            .finally(() => {
                window.history.back()
                setOpen(false)
            })
    }

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary" onClick={handleClickOpen} label={label}/>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
                <List>
                    <ListItem button onClick={handleClick}>
                        <ListItemText primary="Да"/>
                    </ListItem>
                    <ListItem button onClick={handleClose}>
                        <ListItemText primary="Нет"/>
                    </ListItem>
                </List>

            </Dialog>
        </React.Fragment>
    )
}

DeleteAlert.propTypes = {
    record: PropTypes.object
}
