import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {useNotify} from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {API_ADMIN} from '../const'
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import FetchRequest from "../fetchRequest";

export default function DealerUpdateInfo(props) {
    const {record, label} = props;
    let [open, setOpen] = React.useState(false);
    const notify = useNotify();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };
    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_ADMIN + '/dealers/' + record.id + '/updateinfo',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }),
                body: JSON.stringify(record, ['externalId', 'name', 'contactInfo', 'phone']),
            });
        FetchRequest('Ошибка обновления контрагента', request, notify, ()=>{
            window.history.back();
            setOpen(false)
        })
    };
    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Обновить контрагента</DialogTitle>
                <DialogContent>
                    <TextField
                        defaultValue={record.externalId}
                        autoFocus
                        margin="dense"
                        label="Код 1С"
                        type="text"
                        onChange={(event)=>{record.externalId = event.target.value}}
                    />
                    <TextField
                        defaultValue={record.name}
                        autoFocus
                        margin="dense"
                        label="Наименование"
                        type="text"
                        onChange={(event)=>{record.name = event.target.value}}
                    />
                    <TextField
                        defaultValue={record.contactInfo.phone}
                        autoFocus
                        margin="dense"
                        label="Телефоны"
                        type="text"
                        onChange={(event)=>{record.contactInfo.phone = event.target.value}}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleClick} color="primary">
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

DealerUpdateInfo.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};
