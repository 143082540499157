import React from 'react';
import {
    List,
    Datagrid,
    ReferenceField,
    TextField,
} from 'react-admin';

export const PartnerDiscountsList = props => (
    <List title="Скидки партнеров" {...props}>
        <Datagrid>
            <ReferenceField source="partnerId" label="Партнер" reference="partners" link="show" >
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="partnerDiscount" label="Скидка партнера"/>
            <ReferenceField source="dealerId" label="Контрагент" reference="dealers" link="show" >
                <TextField source="name"/>
            </ReferenceField>
            <TextField source="dealerDiscount" label="Скидка контрагента"/>
            <TextField source="city" label="Город" />
        </Datagrid>
    </List>
);

