import Button from "@material-ui/core/Button";
import React from "react";
import {API_ADMIN} from "../const";

export default function GoodsFileDownload(props) {
    const {record, label} = props;

    const onButtonClick = () => {
        const access_token = localStorage.getItem('access_token');
        fetch(API_ADMIN + '/goodsupdates/download/' + record.id, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Authorization': `Bearer ${access_token}`
            },
        }).then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `Список допов.xlsx`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    };

    return (
        <React.Fragment>
            <Button onClick={onButtonClick} color="primary">
                {label}
            </Button>
        </React.Fragment>
    )
}