import React from 'react';
import {
    Datagrid,
    DateField,
    DateInput,
    Filter,
    FunctionField,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    ShowButton,
    TextField,
    BooleanInput,
    TopToolbar,
    ExportButton,
    Button,
    sanitizeListRestProps
} from 'react-admin';
import { FilterList } from "@material-ui/icons";
import {RecordAutocomplete} from './components/recordAutocomplete'

const AccountOperationFilter = props => (
    <Filter {...props}>
        <ReferenceInput label="Партнер" source="partnerId" reference="partners" alwaysOn perPage={10}>
            {RecordAutocomplete()}
        </ReferenceInput>
        <ReferenceInput label="Контрагент" source="dealerId" reference="dealers" alwaysOn perPage={10}>
            {RecordAutocomplete()}
        </ReferenceInput>
        <SelectInput label="Тип счета" source="accountType" alwaysOn showEmptyOption={false} choices={[
            {id: 1, name: 'Официальный'},
            {id: 2, name: 'Фантики'},
        ]}/>
        <SelectInput label="Тип операции" source="operationType" emptyText="Все" alwaysOn choices={[
            {id: 1, name: 'Зачисление'},
            {id: -1, name: 'Списание'},
        ]}/>
        <SelectInput label="Тип платежа" source="paymentType" emptyText="Все" alwaysOn choices={[
            {id: 1, name: 'Онлайн'},
            {id: 2, name: 'Банкомат'},
            {id: 3, name: 'Офис'},
            {id: 4, name: 'Счет'},
            {id: 5, name: 'С баланса'},
            {id: 6, name: 'Возврат'},
            {id: 7, name: 'Возврат за заказ'},
        ]}/>
        <SelectInput label="Состояние операции" source="operationState" emptyText="Все" alwaysOn choices={[
            {id: 1, name: 'Обработка'},
            {id: 2, name: 'Подтверждена'},
            {id: 3, name: 'Отменена'},
        ]}/>
        <DateInput label="Дата создания от" source="dateFrom" alwaysOn/>
        <DateInput label="Дата создания до" source="dateTo" alwaysOn/>
        <BooleanInput label="Только операции с использованием бонусов Energy" source="isReserve" alwaysOn/>
    </Filter>
)

const ListActions = (props) => {
    const {
      className,
      exporter,
      filters,
      maxResults,
      setFilters,
      ...rest
    } = props;
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <Button onClick={() => setFilters({})} label="Сбросить фильтры">
          <FilterList />
        </Button>
        <ExportButton/>
      </TopToolbar>
    );
  };

export const AccountOperationList = ({permissions, ...props}) => (
    <List filters={<AccountOperationFilter/>}
          exporter={permissions?.includes('super_admin') || permissions?.includes('chief_admin') ? props.exporter : false}
          title="Платежи" {...props} actions={<ListActions/>}>
        <Datagrid>
            <TextField source="id" label="Ид"/>
            <DateField source="createdAt" showTime label="Дата создания"/>
            <DateField source="updatedAt" showTime label="Дата Обновления"/>
            <FunctionField label="Тип операции" render={record => {
                switch (record.operationType) {
                    case 1:
                        return "Зачисление"
                    case -1:
                        return "Списание"
                    default:
                        return "Не указан"
                }
            }}/>
            <FunctionField label="Тип платежа" render={record => {
                switch (record.paymentType) {
                    case 1:
                        return "Онлайн"
                    case 2:
                        return "Банкомат"
                    case 3:
                        return "Офис"
                    case 4:
                        return "Счет"
                    case 5:
                        return "С баланса"
                    case 6:
                        return "Возврат"
                    case 7:
                        return "Возврат за заказ"
                    default:
                        return "Не указан"
                }
            }}/>
            <FunctionField label="Состояние операции" render={record => {
                switch (record.operationState) {
                    case 1:
                        return "Обработка"
                    case 2:
                        return "Подтверждена"
                    case 3:
                        return "Отклонена"
                    default:
                        return "Не указано"
                }
            }}/>
            <FunctionField label="Сумма" render={record => record.amount / 100}/>
            <TextField source="reservedScore" label="Использовано бонусов"/>
            <TextField source="comment" label="Комментарий"/>
            <ReferenceField source="dealerId" label="Контрагент" reference="dealers" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="partnerId" label="Партнер" reference="partners" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="orderId" label="Заказ" reference="orders" link="show">
                <TextField source="orderNumber"/>
            </ReferenceField>
            <ShowButton/>
        </Datagrid>
    </List>
);

