import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {useNotify} from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {API_ADMIN} from '../const'

export default function OrderToDev(props) {
    const {record, label} = props;
    let [open, setOpen] = React.useState(false);
    const notify = useNotify();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };
    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_ADMIN + '/orders/'+record.id+'/copytodev',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}` }),
            });
        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    response.json().then(json => {
                        notify(`Ошибка при копировании заказа: ${json}`);
                    })
                } else {
                    response.json().then(json => {
                        notify(`Заказ успешно скопирован на дев! Имя заказа: ${json}`);
                    })
                }
            })
            .catch((e) => {
                notify(`Ошибка при копировании заказа`, 'error')
            })
            .finally(() => {
                window.history.back();
                setOpen(false)
            });
    };
    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Скопировать все конструкции на дев?</DialogTitle>
                <List>
                    <ListItem button onClick={handleClick}>
                        <ListItemText primary="Да"/>
                    </ListItem>
                    <ListItem button onClick={handleClose}>
                        <ListItemText primary="Нет"/>
                    </ListItem>
                </List>

            </Dialog>
        </React.Fragment>
    )
}

OrderToDev.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};
