import * as React from 'react'
import {Coordinates} from '../models/coordinates'
import {Address} from '../models/address'
import {GUID} from '../helpers/guid'
import styles from './ymaps.module.css'
import {YMAPS_API_KEY} from '../../const'

type Timeout = NodeJS.Timeout

export class MapPlacemark {
    id: number
    name: string
    coordinates: Coordinates

    constructor(id: number, name: string, coordinates: Coordinates) {
        this.id = id
        this.name = name
        this.coordinates = coordinates
    }
}

interface IYmapsProps {
    placemarks?: MapPlacemark[]
    selectedPlacemark?: MapPlacemark
    onSelect?: (placemark: MapPlacemark) => void
    zoom?: number
    address?: Address
    className?: string
    onChangeCoords?: (coordinates: Coordinates) => void
    onClickCoords?: (coordinates: Coordinates) => void
}

const RussiaPlacemark = new MapPlacemark(1, 'Россия', new Coordinates(61.698653, 99.505405))

export class Ymaps extends React.Component<IYmapsProps> {
    private _timer!: Timeout
    private _myMap: any
    private _yMapsId = `yMaps${GUID()}`

    componentDidMount(): void {
        let hasYmapScript = false
        document.body.querySelectorAll('script').forEach(script => {
            if (script.src.includes('api-maps.yandex')) {
                hasYmapScript = true
            }
        })

        if (!hasYmapScript) {
            const script = document.createElement('script')
            script.src = `https://api-maps.yandex.ru/2.1/?apikey=${YMAPS_API_KEY}&lang=ru_RU`

            document.body.appendChild(script)
        }

        const checkYmaps = (): void => {
            this._timer = setTimeout(() => {
                const ymaps = (window as any).ymaps
                if (ymaps?.ready) {
                    this._init()
                    return
                }
                checkYmaps()
            }, 400)
        }

        checkYmaps()
    }

    shouldComponentUpdate(nextProps: Readonly<IYmapsProps>): boolean {
        if (this.props.address && nextProps.address) {
            return !this.props.address.equals(nextProps.address)
        }

        return true
    }

    componentDidUpdate(): void {
        void this._updatePlacemarks()
    }

    componentWillUnmount(): void {
        clearTimeout(this._timer)
    }

    render(): JSX.Element {
        return <div className={`${styles.ymaps} ${this.props.className ?? ''}`} id={this._yMapsId} />
    }

    private _select(placemark: MapPlacemark): void {
        this.props.onSelect && this.props.onSelect(placemark)
    }

    private async _updatePlacemarks(): Promise<void> {
        if (!this._myMap) {
            return
        }

        this._myMap.geoObjects.removeAll()

        let selectedPlacemark = this.props.selectedPlacemark ?? RussiaPlacemark

        if (this.props.address) {
            const ymaps = (window as any).ymaps
            const res = await ymaps.geocode(this.props.address.toString(), {results: 1})
            const firstGeoObject = res.geoObjects.get(0)
            const coords = firstGeoObject.geometry.getCoordinates()
            this.props.onChangeCoords && this.props.onChangeCoords(new Coordinates(coords[0], coords[1]))
            selectedPlacemark = new MapPlacemark(-1, this.props.address.toString(), new Coordinates(coords[0], coords[1]))
        }

        const placemarks = this.props.placemarks ?? []

        if (placemarks.length === 0) {
            placemarks.push(selectedPlacemark)
        }

        const geoObjects = placemarks.map(placemark => {
            const placeMark = new (window as any).ymaps.Placemark(
              [placemark.coordinates.latitude, placemark.coordinates.longitude],
              {
                  iconCaption: placemark.name,
                  balloonContent: placemark.name
              },
              {
                  preset: selectedPlacemark && selectedPlacemark.id === placemark.id ? 'islands#redIcon' : 'islands#blueIcon'
              }
            )

            placeMark.events.add(['balloonopen'], () => {
                this._select(placemark)
            })

            return placeMark
        })

        geoObjects.forEach(geoObject => {
            this._myMap.geoObjects.add(geoObject)
        })

        if (selectedPlacemark) {
            this._myMap.setCenter([selectedPlacemark.coordinates.latitude, selectedPlacemark.coordinates.longitude], this.props.zoom ?? 16, {
                duration: 500
            })
        }
    }

    private _init(): void {
        const ymaps = (window as any).ymaps

        ymaps.ready(() => {
            const placemark = this.props.selectedPlacemark || RussiaPlacemark

            this._myMap = new ymaps.Map(this._yMapsId, {
                center: [placemark.coordinates.latitude, placemark.coordinates.longitude],
                zoom: this.props.zoom ?? 16,
                controls: ['zoomControl', 'fullscreenControl']
            })

            this._myMap.events.add('click', (e: any) => {
                const coords = e.get('coords')
                this.props.onClickCoords && this.props.onClickCoords(new Coordinates(coords[0], coords[1]))
            })

            void this._updatePlacemarks()
        })
    }
}
