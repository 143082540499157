import PropTypes from "prop-types";
import {NumberField, SimpleShowLayout,} from "react-admin";
import React from "react";


export default function FilialExternalFields(props) {
    const {record} = props;
    return (
        <React.Fragment>
            <SimpleShowLayout {...props}>
                {
                    (record?.partner?.isSubdealershipAvailable && record?.isExternal) &&
                    !record?.partner?.isSubdealershipBasedOnDiscounts &&
                    <NumberField source="margin.percent" label="Наценка на конструкции (%)"/>
                }
                {
                    record?.partner?.isSubdealershipAvailable && record?.isExternal &&
                    record?.partner?.isAdditionalMarginsAvailable && !record?.partner?.isSubdealershipBasedOnDiscounts &&
                    <NumberField source="laminationMargin.percent" label="Наценка на ламинацию (%)"/>
                }
                {
                    record?.partner?.isSubdealershipAvailable && record?.isExternal &&
                    record?.partner?.isAdditionalMarginsAvailable && !record?.partner?.isSubdealershipBasedOnDiscounts &&
                    <NumberField source="glassMargin.percent" label="Наценка на стеклопакеты (%)"/>
                }
                {
                    record?.partner?.isSubdealershipAvailable && record?.isExternal &&
                    record?.partner?.isAdditionalMarginsAvailable && !record?.partner?.isSubdealershipBasedOnDiscounts &&
                    <NumberField source="tintMargin.percent" label="Наценка на тонировку (%)"/>
                }
                {
                    record?.partner?.isSubdealershipAvailable && record?.isExternal &&
                    record?.partner?.isAdditionalMarginsAvailable && !record?.partner?.isSubdealershipBasedOnDiscounts &&
                    <NumberField source="entranceDoorMargin.percent" label="Наценка на входные двери (%)"/>
                }
                {
                    record?.partner?.isSubdealershipAvailable && record?.isExternal &&
                    record?.partner?.isAdditionalMarginsAvailable && !record?.partner?.isSubdealershipBasedOnDiscounts &&
                    <NumberField source="nonstandardMargin.percent" label="Наценка на нестандарт (%)"/>
                }
                {
                    record?.partner?.isSubdealershipAvailable && record?.isExternal &&
                    record?.partner?.isAdditionalMarginsAvailable && !record?.partner?.isSubdealershipBasedOnDiscounts &&
                    <NumberField source="aluminumMargin.percent" label="Наценка на холодный алюминий (%)"/>
                }
                {
                    record?.partner?.isSubdealershipAvailable && record?.isExternal && record?.partner?.isSubdealershipBasedOnDiscounts &&
                    <NumberField source="subdealerGoodsDiscount.percent" label="Скидка на допы (%)"/>
                }
                {
                    record?.partner?.isSubdealershipAvailable && record?.isExternal && record?.partner?.isSubdealershipBasedOnDiscounts &&
                    <NumberField source="subdealerConstrDiscount.percent" label="Скидка на стандарт (%)"/>
                }
                {
                    record?.partner?.isSubdealershipAvailable && record?.isExternal && record?.partner?.isSubdealershipBasedOnDiscounts &&
                    <NumberField source="subdealerNonstandardDiscount.percent" label="Скидка на нестандарт (%)"/>
                }
                {
                    record?.partner?.isSubdealershipAvailable && record?.isExternal && record?.partner?.isSubdealershipBasedOnDiscounts &&
                    <NumberField source="subdealerLaminationDiscount.percent" label="Скидка на ламинацию (%)"/>
                }
                {
                    record?.partner?.isSubdealershipAvailable && record?.isExternal && record?.partner?.isSubdealershipBasedOnDiscounts &&
                    <NumberField source="subdealerWarmAlDiscount.percent" label="Скидка на теплый алюминий (%)"/>
                }
                {
                    record?.partner?.isSubdealershipAvailable && record?.isExternal && record?.partner?.isSubdealershipBasedOnDiscounts &&
                    <NumberField source="subdealerColdAlDiscount.percent" label="Скидка на холодный алюминий (%)"/>
                }
            </SimpleShowLayout>
        </React.Fragment>
    )
}

FilialExternalFields.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};