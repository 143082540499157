import React from 'react';
import {
    TopToolbar,
    ExportButton,
    AutocompleteInput,
    Datagrid,
    DateField,
    DateInput,
    EmailField,
    Filter,
    List,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    ShowButton,
    SimpleShowLayout,
    TextField,
    TextInput,
    Button,
    sanitizeListRestProps
} from 'react-admin';
import { FilterList } from "@material-ui/icons";
import UserUpdateInfo from "./userUpdateInfo";
import UserUpdatePassword from "./userUpdatePassword";
import {RecordAutocomplete} from '../components/recordAutocomplete'
import RegisterRegionalManager from './registerRM';

function Filters(permissions, ...props) {
    if (permissions?.includes('rm_admin')) {
        return (
            <Filter {...props}>
                <TextInput label="Поиск" source="q" alwaysOn/>
                <ReferenceInput label="Партнер" source="partnerId" reference="partners" alwaysOn perPage={10}>
                    {RecordAutocomplete()}
                </ReferenceInput>
                <SelectInput label="Роль" source="userRole" alwaysOn emptyText="Все" choices={[
                    {id: "BossManager", name: 'Босс менеджер'},
                    {id: "ChiefManager", name: 'Главный менеджер'},
                    {id: "CommonManager", name: 'Менеджер'},
                    {id: "NonqualifiedBossManager", name: 'Неподтвержденный босс менеджер'},
                ]}/>
                <DateInput label="Дата регистрации от" source="dateFrom" alwaysOn/>
                <DateInput label="Дата регистрации до" source="dateTo" alwaysOn/>
            </Filter>
        )
    } else {
        return (
            <Filter {...props}>
                <TextInput label="Поиск" source="q" alwaysOn/>
                <ReferenceInput label="Партнер" source="partnerId" reference="partners" alwaysOn perPage={10}>
                    {RecordAutocomplete()}
                </ReferenceInput>
                <ReferenceInput label="Филиал" source="filialId" reference="filials" alwaysOn perPage={10}>
                    <AutocompleteInput optionText="name" emptyText="Все"/>
                </ReferenceInput>
                <SelectInput label="Роль" source="userRole" alwaysOn emptyText="Все" choices={[
                    {id: "BossManager", name: 'Босс менеджер'},
                    {id: "ChiefManager", name: 'Главный менеджер'},
                    {id: "CommonManager", name: 'Менеджер'},
                    {id: "NonqualifiedBossManager", name: 'Неподтвержденный босс менеджер'},
                ]}/>
                <DateInput label="Дата регистрации от" source="dateFrom" alwaysOn/>
                <DateInput label="Дата регистрации до" source="dateTo" alwaysOn/>
            </Filter>
        )
    }
}

const ListActions = (props) => {
    const {
      className,
      exporter,
      filters,
      maxResults,
      setFilters,
      ...rest
    } = props;
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <Button onClick={() => setFilters({})} label="Сбросить фильтры">
          <FilterList />
        </Button>
        <RegisterRegionalManager label="Добавить РМ"/>
        <ExportButton/>
      </TopToolbar>
    );
  };

export const UserList = ({permissions, ...props}) => {
    return (
        <List filters={Filters(permissions, props)}
              exporter={permissions?.includes('super_admin') || permissions?.includes('chief_admin') ? props.exporter : false}
              title="Пользователи" {...props} actions={<ListActions/>}>
            <Datagrid>
                <TextField source="id" label="Ид"/>
                <DateField source="createdAt" showTime label="Дата регистрации"/>
                <DateField source="deletedAt" showTime label="Дата Удаления"/>
                <TextField source="username" label="Логин"/>
                <EmailField source="email" label="Email"/>
                <TextField source="filial.city.name" label="Город"/>
                <TextField source="userRoles" label="Роль"/>
                <ReferenceField source="selectedDealerId" label="Код 1С" reference="dealers" link="show">
                    <TextField source="externalId"/>
                </ReferenceField>
                {
                    !permissions?.includes('rm_admin') &&
                    <ReferenceField source="partnerId" label="Телефоны" reference="partners" link="show">
                        <TextField source="contactInfo.phone"/>
                    </ReferenceField>
                }
                {
                    !permissions?.includes('rm_admin') &&
                    <ReferenceField source="filialId" label="Филиал" reference="filials" link="show">
                        <TextField source="id"/>
                    </ReferenceField>
                }
                {
                    !permissions?.includes('rm_admin') &&
                    <ShowButton/>
                }
            </Datagrid>
        </List>
    );
};

export const UserShow = ({permissions, ...props}) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" label="Ид"/>
            <DateField source="createdAt" showTime label="Дата регистрации"/>
            <DateField source="updatedAt" showTime label="Дата последнего обновления"/>
            <DateField source="deletedAt" showTime label="Дата Удаления"/>
            <TextField source="username" label="Логин"/>
            <EmailField source="email" label="Email"/>
            {
                (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                <UserUpdateInfo label="Обновить информацию"/>
            }
            <UserUpdatePassword label="Обновить пароль"/>
            <TextField source="filial.city.name" label="Город"/>
            <TextField source="userRoles" label="Роль"/>
            <ReferenceField source="selectedDealerId" label="Код 1С" reference="dealers" link="show">
                <TextField source="externalId"/>
            </ReferenceField>
            <ReferenceField source="partnerId" label="Партнер" reference="partners" link="show">
                <TextField source="name"/>
            </ReferenceField>
            <ReferenceField source="filialId" label="Филиал" reference="filials" link="show">
                <TextField source="name"/>
            </ReferenceField>
        </SimpleShowLayout>
    </Show>
)
