import React from "react";
import {useNotify} from "react-admin";
import {API_BACKEND} from "../const";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from "prop-types";

export default function AmqpHandlerRestart(props) {
    const {record, label} = props;
    let [open, setOpen] = React.useState(false);
    let city = {
        'id': 0,
        'name': ''
    }
    const notify = useNotify();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };
    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_BACKEND + '/amqphandlers/' + record.id + '/restart',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }),
                body: JSON.stringify(city),
            });
        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    response.json().then(json => {
                        notify(`Ошибка перезапуска слушателя: ${json}`, 'error');
                    })
                } else {
                    response.text().then(json => {
                        notify(`${json}`);
                    })
                }
            })
            .catch((e) => {
                notify(`Ошибка выполнения запроса`, 'error')
            })
            .finally(() => {
                window.history.back();
                setOpen(false)
            });
    };
    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Перезапустить слушателя?</DialogTitle>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleClick} color="primary">
                        Да
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

AmqpHandlerRestart.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};