import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useNotify, Button } from 'react-admin'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import { cities } from '../cities'
import { API_ADMIN } from '../const'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'
import { factories } from '../factories'
import { alertLinkTypes } from "./alertLinkTypes";
import {alertDevices, findAlertDevice} from './alertDevices'

export default function UpdateAlert (props) {
    const { label } = props

    const [record, setRecord] = useState(props.record)
    let [open, setOpen] = useState(false)

    const useStyles = makeStyles({
        option: {
            fontSize: 15,
            '& > span': {
                marginRight: 10,
                fontSize: 18
            }
        }
    })

    const notify = useNotify()
    const classes = useStyles()

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleClick = () => {
        const access_token = localStorage.getItem('access_token')
        const request = new Request(API_ADMIN + '/alerts/' + record.id,
            {
                method: 'PUT',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }),
                body: JSON.stringify(record, ['id', 'title', 'description', 'factoryId', 'cityId', 'link', 'linkType', 'linkText', 'color', 'showOnWeb', 'showOnMobile'])
            })

        fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    response.json().then(json => {
                        notify(`Ошибка обновления оповещения: ${json}`)
                    })
                } else {
                    notify('Данные об оповещении обновлены')
                }
            })
            .catch(() => {
                notify(`Ошибка выполнения запроса`, 'error')
            })
            .finally(() => {
                window.history.back()
                setOpen(false)
            })
    }

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary" onClick={handleClickOpen} label={label}/>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Обновить оповещение</DialogTitle>
                <DialogContent>
                    <Autocomplete
                        value={factories.find(value => value.id === record.factoryId)}
                        style={{ width: 300 }}
                        options={factories}
                        classes={{
                            option: classes.option
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.name}
                            </React.Fragment>
                        )}
                        onChange={(event, newValue) => {
                            setRecord({
                                ...record,
                                factoryId: newValue?.id ?? record.factoryId,
                                cityId: 0
                            })
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Выберите завод"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password' // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    <Autocomplete
                        value={cities.find(value => value.id === record.cityId) ?? null}
                        style={{ width: 300 }}
                        options={cities.filter(x => x.factoryId === record.factoryId)}
                        classes={{
                            option: classes.option
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.name}
                            </React.Fragment>
                        )}
                        onChange={(event, newValue) => {
                            setRecord({
                                ...record,
                                cityId: newValue?.id ?? 0
                            })
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Выберите город"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password' // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    <TextField
                        value={record.title}
                        autoFocus
                        margin="dense"
                        label="Заголовок"
                        type="text"
                        onChange={(event) => setRecord({ ...record, title: event.target.value })}
                    />
                    <TextField
                        value={record.description}
                        margin="dense"
                        label="Описание"
                        type="text"
                        onChange={(event) => setRecord({ ...record, description: event.target.value })}
                    />
                    <Autocomplete
                        value={alertLinkTypes.find(value => value.id === record.linkType)}
                        style={{ width: 300, margin: '1em 0em' }}
                        options={alertLinkTypes}
                        classes={{
                            option: classes.option
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.name}
                            </React.Fragment>
                        )}
                        onChange={(event, newValue) => {
                            setRecord({
                                ...record,
                                linkType: newValue?.id ?? record.linkType
                            })
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Тип ссылки"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password' // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    <Autocomplete
                        value={findAlertDevice(record)}
                        style={{ width: 300, margin: '1em 0em' }}
                        options={alertDevices}
                        classes={{
                            option: classes.option
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        renderOption={(option) => (
                            <React.Fragment>
                                {option.name}
                            </React.Fragment>
                        )}
                        onChange={(event, newValue) => {
                            setRecord({
                                ...record,
                                showOnWeb: newValue?.showOnWeb ?? record.showOnWeb,
                                showOnMobile: newValue?.showOnMobile ?? record.showOnMobile
                            })
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Тип устройства"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password' // disable autocomplete and autofill
                                }}
                            />
                        )}
                    />
                    <TextField
                        value={record.link}
                        margin="dense"
                        label="Ссылка"
                        type="text"
                        onChange={(event) => setRecord({ ...record, link: event.target.value })}
                    />
                    <TextField
                        value={record.linkText}
                        margin="dense"
                        label="Текст ссылки"
                        type="text"
                        onChange={(event) => setRecord({ ...record, linkText: event.target.value })}
                    />
                    <TextField
                        value={record.color}
                        margin="dense"
                        label="Цвет (hex)"
                        type="text"
                        onChange={(event) => setRecord({ ...record, color: event.target.value })}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" label="Отмена"/>
                    <Button onClick={handleClick} color="primary" label="Сохранить"/>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

UpdateAlert.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object
}
