import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {API_BACKEND} from '../const'

export default function OrderGetOffer(props) {
    const {record, label} = props;
    let [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };
    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        window.open(API_BACKEND + '/orders/'+record.id+'/offer?t='+access_token);
    };
    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Скачать КП?</DialogTitle>
                <List>
                    <ListItem button onClick={handleClick}>
                        <ListItemText primary="Да"/>
                    </ListItem>
                    <ListItem button onClick={handleClose}>
                        <ListItemText primary="Нет"/>
                    </ListItem>
                </List>

            </Dialog>
        </React.Fragment>
    )
}

OrderGetOffer.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};
