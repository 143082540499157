import {
    Datagrid,
    DateField,
    DateTimeInput,
    EmailField,
    Filter,
    List,
    ReferenceInput,
    SelectInput,
    TextField,
    TextInput,
    TopToolbar,
    ExportButton,
    Button,
    sanitizeListRestProps
} from "react-admin";
import { FilterList } from "@material-ui/icons";
import React from "react";
import getFilterValue from './getFilterValue';
import { connect } from 'react-redux';

const mapStateToProps = (state, props) => {
    let q = getFilterValue(state, 'q');
    return {
        ...props,
        q
    };
};

const UserActionsFilterUnconnected = (props) => {
    const { q } = props;
    const [filter, setFilter] = React.useState({ id: q });
    const handleChange = (event) => {
        const val = event.target.value || '';
        setFilter({ id: val });
    }
    return (
        <Filter {...props}>
            <TextInput label="Поиск" source="q" alwaysOn onChange={handleChange}/>
            <SelectInput label="Тип действия" source="type" emptyText="Все" alwaysOn choices={[
                {id: "action", name: 'Изменение заказа'},
                {id: "recalc", name: 'Пересчет'},
                {id: "addrChange", name: 'Изменение адреса/склада'},
            ]}/>
            <DateTimeInput label="Дата действия от" source="dateFrom" alwaysOn/>
            <DateTimeInput label="Дата действия до" source="dateTo" alwaysOn/>
            <ReferenceInput source="construction_id" label="Конструкции" match="q" reference="constructions" filter={filter} alwaysOn>
                <SelectInput optionText="position" optionValue="id" emptyText="Все"/>
            </ReferenceInput>
        </Filter>
    )
}

const UserActionsFilter = connect(
    mapStateToProps,
)(UserActionsFilterUnconnected);

function Place(props) {
    const {record} = props;
    if (record.place != null) {
        const list = record.place.map(node => {
            return (
                <React.Fragment>
                    <span style={{"white-space": "no-break"}}>{node.info}</span>
                    <br/>
                </React.Fragment>
            )
        })
        return (
            <React.Fragment>
                {list}
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>

        </React.Fragment>
    )
}

const ListActions = (props) => {
    const {
      className,
      exporter,
      filters,
      maxResults,
      setFilters,
      ...rest
    } = props;
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <Button onClick={() => setFilters({})} label="Сбросить фильтры">
          <FilterList />
        </Button>
        <ExportButton/>
      </TopToolbar>
    );
  };

export const UserActionsList = ({permissions, ...props}) => {
    return (
        <List filters={<UserActionsFilter/>}
              exporter={props.exporter}
              title="Действия в заказе" {...props} actions={<ListActions/>}>
            <Datagrid>
                <TextField source="id" label="Ид"/>
                <DateField source="time" showTime label="Время действия"/>
                <Place source="place" label="Место"/>
                <TextField source="action" label="Действие"/>
                <EmailField source="type" label="Тип действия"/>
                <TextField source="user.username" label="Пользователь"/>
            </Datagrid>
        </List>
    );
};