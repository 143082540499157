import React from 'react';
import {
    Show,
    ShowButton,
    DateField,
    List,
    Datagrid,
    ReferenceField,
    TextField,
    TextInput,
    Filter,
    BooleanField, FunctionField,
    NumberField,
    SelectInput,
    DateInput,
    downloadCSV,
    TabbedShowLayout,
    Tab,
    TopToolbar,
    ExportButton,
    Button,
    ArrayField,
    sanitizeListRestProps
} from 'react-admin';
import { FilterList } from "@material-ui/icons";
import { unparse as convertToCSV } from 'papaparse/papaparse.min';
import PartnerSubdealership from "./partnerSubdealership";
import PartnerDeleteClient from "./partnerDeleteClient";
import PartnerUpdateInfo from "./partnerUpdateInfo";
import PartnerChangeCity from "./partnerChangeCity";
import PartnerDiscountVisibility from "./partnerDiscountVisibility";
import PartnerMetalImpost from "./partnerIsMetalKreplenieImposta";
import PartnerGost from "./partnerIsGostShouldBeSet";
import AdditionalDiscounts from "./additionalDiscounts";
import PartnerVilaterm from "./partnerIsVilatermShouldBeSet";
import {getStatusPromo} from "./helpers";
import PartnerPromoConfirm from "./partnerPromoConfirm";
import Chip from "@material-ui/core/Chip";

const exporter = partners => {
    const partnersForExport = partners.map(partner => {
        if (partner.createdAt != null) {
            let createdAt = partner.createdAt.split('T');
            partner.createdAt = createdAt[0];
        }
        if (partner.updatedAt != null) {
            let updatedAt = partner.updatedAt.split('T');
            partner.updatedAt = updatedAt[0];
        }
        if (partner.deletedAt != null) {
            let deletedAt = partner.deletedAt.split('T');
            partner.deletedAt = deletedAt[0];
        }
        partner.contactInfo = partner.contactInfo.phone + " " + partner.contactInfo.email
        partner.margin = partner.margin.percent
        partner.goodsMargin = partner.goodsMargin.percent

        return partner;
    });
    const csv = convertToCSV({
        data: partnersForExport
        //,fields: ['id', 'createdAt', 'externalId', 'name', 'contactInfo', 'isDeliveryAvailable', 'factoryId', 'selectedDealerId', ]
    });
    downloadCSV(csv, 'partners');
}

const ListActions = (props) => {
    const {
      className,
      exporter,
      filters,
      maxResults,
      setFilters,
      ...rest
    } = props;
    return (
      <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <Button onClick={() => setFilters({})} label="Сбросить фильтры">
          <FilterList />
        </Button>
        <ExportButton/>
      </TopToolbar>
    );
  };

const PartnerFilter = props => (
    <Filter {...props}>
        <TextInput label="Поиск" source="q" alwaysOn/>
        <SelectInput label="Доступна доставка" source="delivery" emptyText="Все" alwaysOn choices={[
            { id: true, name: 'Да' },
            { id: false, name: 'Нет' },
        ]}/>
        <SelectInput label="Завод" source="factoryId" emptyText="Все" alwaysOn choices={[
            { id: 3, name: 'Пермь' },
            { id: 4, name: 'Электросталь' },
        ]}/>
        <SelectInput label="Подтвержден" source="isConfirmed" emptyText="Все" alwaysOn choices={[
            { id: true, name: 'Да' },
        ]}/>
        <SelectInput label="Удален" source="notDeleted" emptyText="Все" alwaysOn choices={[
            { id: true, name: 'Нет' },
        ]}/>
        <DateInput label="Дата создания от" source="dateFrom" alwaysOn/>
        <DateInput label="Дата создания до" source="dateTo" alwaysOn/>
    </Filter>
)

export const PartnersList = ({permissions, ...props}) => (
    <List filters={<PartnerFilter/>} exporter={permissions?.includes('super_admin') || permissions?.includes('chief_admin') || permissions?.includes('elohina_admin') ? exporter  : false} title="Партнеры" {...props} actions={<ListActions/>}>
        <Datagrid>
            <TextField source="id" label="Ид"/>
            <DateField source="createdAt" showTime label="Дата создания"/>
            <DateField source="deletedAt" showTime label="Дата Удаления"/>
            <TextField source="externalId" label="Код 1С"/>
            <TextField source="name" label="Наименование"/>
            <TextField source="city" label="Город" />
            <BooleanField source="isDeliveryAvailable" label="Доступна доставка"/>
            <BooleanField source="isSubdealershipAvailable" label="Доступно субдилерство"/>
            <TextField source="factoryId" label="Ид завода"/>
            <ReferenceField source="selectedDealerId" label="Контрагент" reference="dealers" link="show" >
                <TextField source="id"/>
            </ReferenceField>
            <TextField source={'discountString'} label={'Скидки'}/>
            <TextField source="contactInfo.phone" label="Телефоны"/>
            {
                !permissions?.includes('rm_admin') &&
                <ShowButton/>
            }
        </Datagrid>
    </List>
);

export const PartnerShow = ({permissions, ...props}) => (
    <Show {...props}>
        <TabbedShowLayout>
            <Tab label='Основное'>
                <TextField source="id" label="Ид"/>
                <DateField source="createdAt" showTime label="Дата создания"/>
                <DateField source="updatedAt" showTime label="Дата последнего обновления"/>
                <DateField source="deletedAt" showTime label="Дата Удаления"/>
                {
                    (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                    <PartnerDeleteClient label="Удалить клиента"/>
                }
                <TextField source="externalId" label="Код 1С"/>
                {
                    (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                    <PartnerUpdateInfo label="Обновить информацию"/>
                }
                <TextField source="name" label="Наименование"/>
                <TextField source="city" label="Город" />
                <PartnerChangeCity label="Сменить город"/>
                <TextField source="contactInfo.phone" label="Телефоны"/>
                <NumberField source="margin.percent" label="Наценка на конструкции"/>
                <NumberField source="goodsMargin.percent" label="Наценка на допы"/>
                <BooleanField source="isDeliveryAvailable" label="Доступна доставка"/>
                <TextField source="factoryId" label="Ид завода"/>
                <ReferenceField source="selectedDealerId" label="Контрагент" reference="dealers" link="show" >
                    <TextField source="id"/>
                </ReferenceField>
                <NumberField source="discount" label="Скидка на конструкции/стандарт"/>
                <NumberField source="discountNonstandard" label="Скидка на нестандарт"/>
                <NumberField source="discountLamination" label="Скидка на ламинацию"/>
                <NumberField source="discountWarmAl" label="Скидка на теплый алюминий"/>
                <NumberField source="discountColdAl" label="Скидка на холодный алюминий"/>
                <NumberField source="discountMetal" label="Скидка на метал"/>
                <NumberField source="discountGoods" label="Скидка на товары"/>
                <NumberField source="discountGlass" label="Скидка на стеклопакеты"/>
                <BooleanField source="showDiscountInProfile" label="Видимость скидки"/>
                {
                    (permissions?.includes('super_admin') || permissions?.includes('chief_admin')) &&
                    <PartnerDiscountVisibility isAdmin={true} label="Настройка видимости скидки"/>
                }
                {
                    (permissions?.includes('common_admin')) &&
                    <PartnerDiscountVisibility label="Настройка видимости скидки"/>
                }
                <BooleanField source="isArmMarkingAvailable" label="Доступна возможность просмотра армирования в заказе" />
                <BooleanField source="isSubdealershipAvailable" label="Доступно субдилерство" />
                <PartnerSubdealership label="Включить/Отключить субдилерство"/>
                <BooleanField source="isVilatermShouldBeSet" label="Принудительная установка вилатерма на все заказы"/>
                <PartnerVilaterm label="Включить/отключить принудительную установку вилатерма"/>
                <BooleanField source="isTenderShouldBeSet" label="Принудительная установка параметра Тендер на все заказы"/>
                <BooleanField source="isMetalKreplenieImposta" label="Принудительная установка металлической пятки импоста"/>
                <PartnerMetalImpost label="Включить/отключить принудительную установку металлической пятки импоста"/>
                <BooleanField source="isGostShouldBeSet" label="Установка параметра ГОСТ"/>
                <PartnerGost label="Включить/отключить установку параметра ГОСТ"/>
                <BooleanField source="skipInAnalytics" label="Пропуск в аналитике"/>
            </Tab>
            <Tab label='Дополнительные скидки'>
                <AdditionalDiscounts writeAccess={permissions?.includes('super_admin') || permissions?.includes('chief_admin')}/>
            </Tab>
            <Tab label='Промокод'>
                <ArrayField source="promos" label={'Промокоды'}>
                    <Datagrid>
                        <TextField source={'promo.code'} label={'Код'} />
                        <FunctionField source={'status'} label={'Статус'} render={(record) => <Chip color={record.status === 'used' ? 'secondary' : 'inherit'} label={getStatusPromo(record.status)} />} />
                        <TextField source={"promo.description"} label={'Описание'} />
                        <FunctionField source={'status'} label={''} render={(record) => record.status === 'unused' ? <PartnerPromoConfirm id={props.id} record={record} label={'Применить'} /> : <span></span>} />
                    </Datagrid>
                </ArrayField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

