import React from 'react'
import { useNotify } from 'react-admin'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { postJson } from '../../api/postJson'
import DialogActions from '@material-ui/core/DialogActions'

export const ForceShippingType = ({ record }) => {
    const [open, setOpen] = React.useState(false)
    const notify = useNotify()

    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)

    const handleClick = value => {
        postJson(`/admin/orders/${record.id}/force/shippingtype`, { value })
            .then(resp => notify(resp))
            .catch(resp => notify(resp.toString(), 'error'))
            .finally(() => {
                handleClose()
                document.location.reload()
            })
    }

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary" onClick={handleOpen}>
                Установить принудительный тип доставки
            </Button>

            <Dialog aria-labelledby="simple-dialog-title" open={open} onClose={handleClose}>
                <DialogTitle id="simple-dialog-title">Установить принудительный тип доставки</DialogTitle>

                <DialogActions>
                    <Button onClick={() => handleClick("delivery")}>Доставка по адресу</Button>
                    <Button onClick={() => handleClick("pickup")}>Доставка на склад</Button>
                    <Button onClick={() => handleClick(null)}>Не указано</Button>
                    <Button onClick={handleClose}>Отмена</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
