import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {useNotify} from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {API_ADMIN} from '../const'
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FetchRequest from "../fetchRequest";

class HandleParamsRequest {
    constructor(handleColor, handleType) {
        let [constructions, setConstructions] = React.useState([]);
        this.handleColor = handleColor;
        this.handleType = handleType;
        this.constructions = constructions;
        this.setConstructions = setConstructions;
    }
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function OrderSetHandleParams(props) {
    const {record, label} = props;
    let [open, setOpen] = React.useState(false);
    let handleParamsRequest = new HandleParamsRequest("", "")

    if (record.constructions === null || record.constructions === undefined) {
        record.constructions = [];
    }

    const notify = useNotify();
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false)
    };
    const handleClick = () => {
        const access_token = localStorage.getItem('access_token');
        const request = new Request(API_ADMIN + '/orders/' + record.id + '/sethandleparams',
            {
                method: 'POST',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${access_token}`
                }),
                body: JSON.stringify(handleParamsRequest, ['handleColor', 'handleType', 'constructions']),
            });
        FetchRequest('Ошибка установки параметров ручек', request, notify, () => {
            window.history.back();
            setOpen(false)
        });
    };
    const handleChange = (event) => {
        handleParamsRequest.setConstructions(event.target.value);
    };

    return (
        <React.Fragment>
            <Button variant="outlined" color="primary"
                    disabled={record.state !== 'draft' && record.state !== 'needs_recalc' && record.state !== 'calc_errors'}
                    onClick={handleClickOpen}>
                {label}
            </Button>
            <Dialog aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">Параметры ручек</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Введите цвет и тип ручки, а также выберите конструкции заказа, в которых необходимо произвести
                        изменениея.
                        Либо не выбирайте конструкции, чтобы обновить их все.
                        Оставьте значение пустым, чтобы не обновлять какой-либо параметр.
                    </DialogContentText>
                    <TextField
                        defaultValue={handleParamsRequest.handleType}
                        autoFocus
                        margin="dense"
                        label="Тип ручки"
                        type="text"
                        onChange={(event) => {
                            handleParamsRequest.handleType = event.target.value
                        }}
                        fullWidth
                    />
                    <TextField
                        defaultValue={handleParamsRequest.handleColor}
                        margin="dense"
                        label="Цвет ручки"
                        type="text"
                        onChange={(event) => {
                            handleParamsRequest.handleColor = event.target.value
                        }}
                        fullWidth
                    />
                    <FormControl>
                        <InputLabel id="mutiple-chip-label">Конструкции</InputLabel>
                        <Select
                            labelId="mutiple-chip-label"
                            multiple
                            value={handleParamsRequest.constructions}
                            onChange={handleChange}
                            input={<Input id="select-multiple-chip"/>}
                            renderValue={(selected) => (
                                <div>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value}/>
                                    ))}
                                </div>
                            )}
                            MenuProps={MenuProps}>
                            {record.constructions.map((construction) => (
                                <MenuItem key={construction.position} value={construction.position}>
                                    {`Констр. ${construction.position}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleClick} color="primary">
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}

OrderSetHandleParams.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
};
